<template>
	<div class="main">

	</div>
</template>

<script>
export default {
   data() {
	   return {
	   }
   },
   components: {
	 
   },
   
   mounted() {
		this.home();
   },
   watch: {
   },
   methods: {
		home:function(){//利润表
			this.$router.push({
				path: '/home',
				// query: {
				// 	stage: this.$route.query.stage ?  this.$route.query.stage : '2',
				// 	type: this.$route.query.type ?  this.$route.query.type : '12',
				// 	reportId: this.$route.query.reportId
				// }
			})
		},
	  
   },
}
</script>
