// 配置全局接口域名
const domain = 'http://test.flcccc.com/';
const ajaxUrl='flow/';
const turingProject = 'flow/turing/';  //图灵估值

// const turingProject = 'flow/'; //图灵估值


export default {
	testUrl: domain,
	ajaxUrl:ajaxUrl,
	turingProject: turingProject
}
