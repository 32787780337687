<template>
	<div :id="id"></div>
</template>
<script>
	import * as echarts from 'echarts/lib/echarts.js'
	import formatCurrency from '../../assets/js/numberToCurrency'


	export default ({
		props: [
			'chartData', 'id', 'year'
		],
		data() {
			return {
				chartName: [],
				chartValue: []
			}
		},
		mounted() {
			this.chartName = [this.year + "E", this.year + 1 + "E", this.year + 2 + "E", this.year + 3 + "E", this
				.year + 4 + "E"
			];
			this.chartValue = this.chartData.pillar;
			this.initChart()
			console.log(this.chartValue)

		},
		methods: {
			initChart: function() {
				this.chart = echarts.init(document.getElementById(this.id));
				this.chartValue.netProfit = this.change(this.chartValue.netProfit)
				this.chartValue.ebitda = this.change(this.chartValue.ebitda)
				this.chartValue.capex = this.change(this.chartValue.capex)
				this.chartValue.workingCapital = this.change(this.chartValue.workingCapital)

				this.setOptions(this.id, this.chartName, this.chartValue)
			},
			setOptions: function(id, dataxAxis, data) {

				var colors = [{
						type: "linear",
						x: 0,
						x2: 1,
						y: 0,
						y2: 0,
						colorStops: [{
							offset: 0,
							color: "#A6A0FA",
						}, {
							offset: 0.5,
							color: "#A6A0FA",
						}, {
							offset: 0.5,
							color: "#BBBBF9",
						}, {
							offset: 1,
							color: "#BBBBF9",
						}]
					}, {
						type: "linear",
						x: 0,
						x2: 1,
						y: 0,
						y2: 0,
						colorStops: [{
							offset: 0,
							color: "#E0A0FA",
						}, {
							offset: 0.5,
							color: "#E0A0FA",
						}, {
							offset: 0.5,
							color: "#E9BBF9",
						}, {
							offset: 1,
							color: "#E9BBF9",
						}]
					}, {
						type: "linear",
						x: 0,
						x2: 1,
						y: 0,
						y2: 0,
						colorStops: [{
							offset: 0,
							color: "#79E5BE",
						}, {
							offset: 0.5,
							color: "#79E5BE",
						}, {
							offset: 0.5,
							color: "#B5F8E1",
						}, {
							offset: 1,
							color: "#B5F8E1",
						}]
					},
					{
						type: "linear",
						x: 0,
						x2: 1,
						y: 0,
						y2: 0,
						colorStops: [{
							offset: 0,
							color: "#7DBDFE",
						}, {
							offset: 0.5,
							color: "#7DBDFE",
						}, {
							offset: 0.5,
							color: "#B7DDFA",
						}, {
							offset: 1,
							color: "#B7DDFA",
						}]
					}
				];
				var barWidth = 50;

				var fontSize = 14;
				var data = data;
				var option = {
					title: {
						show: true,
						text: '现金流预测',
						textStyle: {
							color: '#282828',
							align: 'center'
						},
						left: 'center',
						top: 'top'
					},
					animation: false,
					tooltip: {
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						},
						formatter(params) {
							// 只展示柱子对应的内容，把顶部底部的 tooltip 过滤掉
							return params.reduce((pre, i) => {
								// console.log(i)
								if (i.componentSubType === "line") {
									i.marker = i.marker.replace(/\[object Object\]/, i.color);
									i.value =
										`<span style="flex: 1; text-align: right; margin-left: 16px;">${(isNaN(i.value)?'0.00':formatCurrency.formateNum(i.value.toString(),2))}</span>`;
									const current =
										`<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.seriesName} ${i.value}</div>`;
									return `${pre}${current}`;
								}
								if (i.componentSubType === 'bar') {
									i.marker = i.marker.replace(/\[object Object\]/, i.color
										.colorStops[1].color);
									i.value =
										`<span style="flex: 1; text-align: right; margin-left: 16px;">${(isNaN(i.value)?'0.00':formatCurrency.formateNum(i.value.toString(),2))}</span>`;
									const current =
										`<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.seriesName} ${i.value}</div>`;
									return `${pre}${current}`;
								}
								return pre;
							}, '');
						}
					},
					legend: {
						selectedMode: false,
						data: [{
							name: '净利润',
							width: 20,
							itemWidth: 20,
							height: 12,
							icon: 'image://' + require('../../assets/img/report/columnarIcon1.svg'),
						}, {
							name: '非现金支出',
							width: 20,
							itemWidth: 20,
							height: 12,
							icon: 'image://' + require('../../assets/img/report/columnarIcon2.svg'),
						},{
							name: '营运资本增加',
							width: 20,
							itemWidth: 20,
							height: 12,
							icon: 'image://' + require('../../assets/img/report/columnarIcon4.svg'),
						}],
						bottom: 0,
						textStyle: {
							fontSize: fontSize
						}
					},

					xAxis: {
						type: 'category',
						data: dataxAxis,
						axisTick: {
							show: false
						},
						axisLabel: {
							textStyle: {
								fontSize: fontSize,
								color: '#282828'
							},
							margin: 20
						},
						axisLine: {
							lineStyle: {
								color: '#282828'
							}
						}
					},
					yAxis: {
						type: 'value',
						name: '万元',
						splitLine: {
							show: false
						},
						axisLabel: {
							textStyle: {
								color: '#282828'
							}
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#282828'
							}
						},
					},
					series: [{
							name: '净利润',
							type: 'bar',
							barMaxWidth: 50,
							stack: '总量',
							label: {
								normal: {
									show: true,
									color:'#282828',
									position: 'inside',
									formatter: function(p) {
										return formatCurrency.formateNum(p.value.toString(), 2);
									}
								}
							},
							itemStyle: {
								normal: {
									color: colors[0]
								}
							},
							data: data.netProfit
						},
						{
							name: '净利润',
							type: 'pictorialBar',
							stack: '总量',
							symbol: "diamond",
							symbolSize: [barWidth, 12],
							symbolPosition: 'end',
							itemStyle: {
								normal: {
									color: "#D5D2FD",
									label: {
										show: false
									}
								},
							},
							z: 13,
							data: this.pictorialBar1(data.netProfit)
						},
						{
							name: '非现金支出',
							type: 'bar',
							barMaxWidth: 50,
							stack: '总量',
							label: {
								normal: {
									show: true,
									color: '#282828',
									position: 'top',
									formatter: function(p) {
										return formatCurrency.formateNum(p.value.toString(), 2);
									},
								}
							},
							itemStyle: {
								normal: {
									color: colors[1]
								}
							},
							data: data.ebitda
						},
						{
							name: '非现金支出',
							type: 'pictorialBar',
							stack: '总量',
							symbol: "diamond",
							symbolSize: [barWidth, 12],
							symbolPosition: 'end',
							itemStyle: {
								normal: {
									color: "#F0D2FF",
									label: {
										show: false,
									}
								},
							},
							z: 13,
							data: this.pictorialBar2(data.ebitda, data.netProfit)
						},
						{
							name: '营运资本增加',
							type: 'bar',
							barMaxWidth: 50,
							stack: '总量',
							label: {
								normal: {
									show: true,
									color: '#000000',
									position: 'bottom',
									formatter: function(p) {
										return formatCurrency.formateNum(p.value.toString(), 2);
									}
								},
							},
							itemStyle: {
								color: colors[3]
							},
							data: data.workingCapital
						},
						{
							name: '营运资本增加',
							type: 'pictorialBar',
							stack: '总量',
							symbol: "diamond",
							symbolSize: [barWidth, 12],
							symbolPosition: 'end',
							z: 13,
							itemStyle: {
								normal: {
									color: '#D7EEFF',
									label: {
										show: false
									}
								},
							},
							data: this.pictorialBar4(data.workingCapital, data.netProfit, data.ebitda)
						}
					]
				};
				var myChart = echarts.getInstanceByDom(document.getElementById(id));
				if (myChart == undefined) {
					myChart = echarts.init(document.getElementById(id));
				}
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
			},
			change: function(arr) { //柱状图每一项中数值为0时的处理方法
				var len = arr.length;
				for (var i = 0; i < len; i++) {
					if (arr[i] == 0) {
						arr[i] = "-";
					}
				}
				return arr;
			},
			pictorialBar1: function(data) {
				var arr = [];
				data.forEach(function(ele, i) {
					if (ele < 0) {
						arr[i] = {
							value: ele,
							symbolOffset: [0, 6]
						}
					} else {
						arr[i] = {
							value: ele,
							symbolOffset: [0, -7]
						}
					}
				});
				return arr
			},
			pictorialBar2: function(data, data2) {
				var arr2 = [];
				var y = [];
				data.forEach(function(value1, i) {
					if ((value1 > 0 && data2[i] > 0) || (value1 < 0 && data2[i] < 0)) {
						y.push(value1 + data2[i])
					} else {
						var num = data[i];
						if (data[i] > 0) {
							if (data2[i] > 0) {
								num += data2[i]
							}
						} else {
							var num = data[i];
							if (data2[i] < 0) {
								num += data2[i]
							}
						}
						y.push(num)
					}
				})
				data.forEach(function(ele, i) {
					if (ele < 0) {
						arr2[i] = {
							value: y[i],
							symbolOffset: [0, 6]
						}
					} else {
						arr2[i] = {
							value: y[i],
							symbolOffset: [0, -7],
						}
					}
				});
				return arr2
			},
			pictorialBar3: function(data, data2, data3) {
				var arr3 = [];
				var y2 = [];
				data.forEach(function(value1, i) {
					if ((value1 > 0 && data2[i] > 0 && data3[i] > 0) || (value1 < 0 && data2[i] < 0 &&
							data3[i] < 0)) {
						y2.push(value1 + data2[i] + data3[i])
					} else {
						var num = data[i];
						if (data[i] > 0) {
							console.log(data[i], i)
							if (data2[i] > 0) {
								num += data2[i]
							}
							if (data3[i] > 0) {
								num += data3[i]
							}
						} else {
							var num = data[i];
							if (data2[i] < 0) {
								num += data2[i]
							}
							if (data3[i] < 0) {
								num += data3[i]
							}
						}
						y2.push(num)
					}
				})
				data.forEach(function(ele, i) {
					if (ele < 0) {
						arr3[i] = {
							value: y2[i],
							symbolOffset: [0, 6]
						}
					} else {
						arr3[i] = {
							value: y2[i],
							symbolOffset: [0, -7]
						}
					}
				});
				return arr3
			},
			pictorialBar4: function(data, data2, data3, data4) {
				var arr4 = [];
				var y3 = [];

				data.forEach(function(value1, i) {
					if ((value1 > 0 && data2[i] > 0 && data3[i] > 0) || (value1 < 0 &&
							data2[i] < 0 && data3[i] < 0 )) {
						y3.push(value1 + data2[i] + data3[i])
					} else {
						var num = data[i];
						if (data[i] > 0) {
							console.log(data[i], i)
							if (data2[i] > 0) {
								num += data2[i]
							}
							if (data3[i] > 0) {
								num += data3[i]
							}
						} else {
							var num = data[i];
							if (data2[i] < 0) {
								num += data2[i]
							}
							if (data3[i] < 0) {
								num += data3[i]
							}
						}
						y3.push(num)
					}
				})
				data.forEach(function(ele, i) {
					if (ele < 0) {
						arr4[i] = {
							value: y3[i],
							symbolOffset: [0, 6]
						}
					} else {
						arr4[i] = {
							value: y3[i],
							symbolOffset: [0, -7]
						}
					}
				});
				return arr4
			}



		}
	})
</script>
<style scoped>
</style>
