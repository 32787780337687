<template>
	<div :id="id"></div>
</template>
<script>
	import * as echarts from 'echarts'
	import formatCurrency from '../../assets/js/numberToCurrency'


	export default ({
		props: [
			'chartData', 'id', 'year'
		],
		data() {
			return {
				chartName: [],
				chartValue1: [],
				chartValue2: []
			}
		},
		mounted() {
			if (this.chartData) {
				this.chartName = [this.year + "E", this.year + 1 + "E", this.year + 2 + "E", this.year + 3 + "E", this
					.year + 4 + "E"
				];
				this.chartValue1 = this.chartData.polyline.ncft;
				for (var i = 0; i < this.chartData.polyline.ncftPercentArr.length; i++) {
					this.chartValue2.push(this.chartData.polyline.ncftPercentArr[i])
				}
				this.chartValue2[0] = "";
				this.initChart()
			}
		},
		methods: {
			initChart: function() {
				this.chart = echarts.init(document.getElementById(this.id));
				this.setOptions(this.id, this.chartName, this.chartValue1, this.chartValue2)
			},
			setOptions: function(id, dataxAxis, dataSeriesOne, dataSeriesTwo) {
				var fontSize = 14;
				var option = {
					title: {
						show: true,
						text: '现金流趋势',
						textStyle: {
							color: '#282828',
							align: 'center'
						},
						left: 'center',
						top: 'top'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						},
						formatter: function(params) {
							// 只展示柱子对应的内容，把顶部底部的 tooltip 过滤掉
							return params.reduce((pre, i) => {
								if (i.componentSubType === "line") {
									i.marker = i.marker.replace(/\[object Object\]/, i.color);
									i.value =
										`<span style="flex: 1; text-align: right; margin-left: 16px;">${(isNaN(i.value)?'':formatCurrency.formateNum(i.value.toString(),2))}</span>`;
									const current =
										`<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.seriesName} ${i.value}</div>`;
									return `${pre}${current}`;
								}
								if (i.componentSubType === 'bar') {
									i.marker = i.marker.replace(/\[object Object\]/, i.color
										.colorStops[1].color);
									i.value =
										`<span style="flex: 1; text-align: right; margin-left: 16px;">${(isNaN(i.value)?'':formatCurrency.formateNum(i.value.toString(),2))}</span>`;
									const current =
										`<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.seriesName} ${i.value}</div>`;
									return `${pre}${current}`;
								}
								return pre;
							}, '');
						},
					},
					legend: {
						selectedMode: false,
						data: [{
							name: 'FCFF',
							width: 20,
							itemWidth: 20,
							height: 12,
							icon: 'image://' + require('../../assets/img/report/FCFF.svg'),
						}, {
							name: 'FCFF增长率'
						}],
						left: '30%',
						bottom: '0',
						itemGap: 50
					},
					xAxis: {
						type: 'category',
						data: dataxAxis,
						axisTick: {
							show: false
						},
						axisLabel: {
							textStyle: {
								fontSize: fontSize,
								color: '#282828'
							}
						},
						axisLine: {
							lineStyle: {
								color: '#282828'
							}
						}
					},
					yAxis: [{
							type: 'value',
							name: '万元',
							axisLine: {
								show: true,
								lineStyle: {
									color: '#282828'
								}
							}
						},
						{
							type: 'value',
							name: '%',
							splitLine: {
								show: false
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: '#282828'
								}
							}
						},
					],
					series: [{
							name: 'FCFF',
							data: dataSeriesOne,
							type: 'bar',
							barMaxWidth: 50,
							z:11,
							label: {
								normal: {
									show: false,
									position: 'top',
									textStyle: {
										color: '#282828'
									},
									formatter: function(p) {
										return formatCurrency.formateNum(p.value.toString(), 2);
									}
								}
							},
							itemStyle: {
								normal: {
									color: {
										type: "linear",
										x: 0,
										x2: 1,
										y: 0,
										y2: 0,
										colorStops: [{
											offset: 0,
											color: "#4ADDE6",
										}, {
											offset: 0.5,
											color: "#4ADDE6",
										}, {
											offset: 0.5,
											color: "#8DEDF3",
										}, {
											offset: 1,
											color: "#8DEDF3",
										}]
									}
								}
							}
						},
						{
							type: 'pictorialBar',
							symbol: "diamond",
							symbolSize: [50, 12],
							symbolPosition: 'end',
							itemStyle: {
								normal: {
									color: "#BFFCFF",
									label: {
										show: true,
										position: 'top', //在上方显示
										textStyle: {
											color: '#282828'
										},
										formatter: function(p) {
											return formatCurrency.formateNum(p.value.toString(), 2);
										}
									}
								},
							},
							z: 13,
							data: this.pictorialBar1(dataSeriesOne)
						},
						{
							name: 'FCFF增长率',
							data: dataSeriesTwo,
							type: 'line',
							yAxisIndex: 1,
							z:14,
							label: {
								normal: {
									show: true,
									position: [-30, -15],
									textStyle: {
										color: '#282828'
									},
									formatter: function(p) {
										return Number(p.value).toFixed(2);
									}
								}
							},
							itemStyle: {
								normal: {
									color: "#EB921E",
								},
							},
							symbol: 'circle', //设置此项则拐点为实心圆
							symbolSize: 8, //拐点圆的大小
						},
					],
				};
				var myChart = echarts.getInstanceByDom(document.getElementById(id));
				if (myChart == undefined) {
					myChart = echarts.init(document.getElementById(id));
				}
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
			},
			pictorialBar1: function(data) {
				var arr = [];
				data.forEach(function(ele, i) {
					if (ele < 0) {
						arr[i] = {
							value: ele,
							symbolOffset: [0, 7],
							symbolPosition: 'end'
						}
					} else {
						arr[i] = {
							value: ele,
							symbolOffset: [0, -7],
							symbolPosition: 'end'
						}
					}
				});
				return arr
			},
			change: function(arr) { //柱状图每一项中数值为0时的处理方法
				var len = arr.length;
				for (var i = 0; i < len; i++) {
					if (arr[i] == 0) {
						arr[i] = "-";
					}
				}
				return arr;
			}
		}
	})
</script>
<style scoped>
</style>
