<template>
	<div>
		<aside :class="['guzhiAside',this.$route.query.type==5?'traceAside':'']">
			<ul>
				<li v-if="item.show" v-for="(item, index) in navList" :class="{liClicks:index==current}"
					@click="categoryNav(index)">
					<span>{{item.text}}</span>
					<!-- <a :href="index">{{item.text}}</a> -->
				</li>
				<li class="completion" v-if="this.$route.query.type!=5">
					<div>
						<span>您已完成</span>
						<span class="progress"><span :style="{width:`${progressNum}%`}"></span></span>
						<span class="progressNum">{{progressNum}}%</span>
					</div>
				</li>
				<li class="trace_completion" v-if="this.$route.query.type==5">
					<div>
						<span>本次价值追踪:</span>
						<ul class="year-container" id="year-container">
							<li v-for="(item, index) in traceList"
								@click="year(item.year,item.valutaionReportId,index)">
								<div></div>
								<p>
									<span>{{item.year}}年</span>
									<!-- item.status  1:审核通过 2:未填写完成 3:填写完成 -->
									<span :class="['show',item.status==1||item.status==3?'blueFont':'']">查看信息</span>
								</p>
							</li>
						</ul>
					</div>
				</li>
			</ul>
		</aside>
		<Modal v-model="myAlert" class-name="vertical-center-modal" @on-ok="ok(skip)" @on-cancel="cancel">
			<img class='warning' src="../../assets/img/filledTuring/remind-01@2x.png">
			<span>当前数据尚未保存，是否确定离开？</span>
		</Modal>



	</div>
</template>

<script>
	import '../../assets/css/common.css';
	import qs from 'qs';
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		data() {
			return {
				current: 0,
				progressNum: 0,
				myAlert: false,
				navList: [{
						text: '基本信息',
						show: true,
						path: '/basicInformation'
					}, {
						text: '软性指标',
						show: true,
						path: '/softInformation'
					},
					{
						text: '财务指标',
						show: true,
						path: '/balanceSheet'
					}, {
						text: '估值报告',
						show: true,
						path: '/submitReport'
					}
				],
				traceList: [],
				skip: 1,
				projectName: ''
			}
		},
		computed: {
			...mapState(['currentPageModify', 'currentPageSave', 'traceYear'])
		},
		mounted() {
			document.title = this.$route.query.type == 7 ? '线上诊断_公司资产评估股权估值,北京在线资产评估公司-心流慧估' :
				'线上估值_公司资产评估股权估值,北京在线资产评估公司-心流慧估';

			if (this.$route.query.type == 7) {
				this.projectName = this.domain.diagnoseProject;
			} else if (this.$route.query.type == 5) {
				this.projectName = this.domain.traceProject;
			} else if (this.$route.query.type == 2) {
				this.projectName = this.domain.valuationProject;
			}

			if (this.$route.query.type == 5) { //价值追踪隐藏综合展示
				this.initNavYear();
				for (var i = 0; i < this.navList.length; i++) {
					if (this.navList[i].text == '企业综合展示') {
						this.navList[i].show = false;
					}
				}
			}

			if (this.$route.query.type != 5) {
				for (var i = 0; i < this.navList.length; i++) {
					if (this.navList[i].text == '估值报告' && this.$route.query.type == 7) {
						this.navList[i].text = '诊断报告';
					}
				}
				if (this.$route.query.type == 7) {
					this.getBasedate();
				}
			}
			if (this.$route.path == '/softInformation') {
				this.current = 1;
			} else if (this.$route.path == '/balanceSheet' || this.$route.path == '/profitStatement' || this.$route.path ==
				'/majorIncomeAnalysis' || this.$route.path == '/majorPolicyStatis' || this.$route.path == '/finanAnalisis'
				) {
				this.current = 2;
			} else if (this.$route.path == '/submitReport') {
				this.current = 3;
			}

			if (this.$route.path == '/submitReport') {
				document.getElementsByClassName('guzhiAside')[0].scrollLeft = 160;
			}

			this.queryPercent()
			// console.log(this.traceYear)
		},
		methods: {
			...mapMutations(['changeTraceYear']),
			categoryNav: function(i) {
				if (this.currentPageModify == 'true' && this.currentPageSave == false) {
					this.myAlert = true;
					this.skip = i;
				} else {
					this.current = i;
					if (this.$route.query.reportId) {
						if (sessionStorage.reportType == 5) {
							this.$router.push({
								path: this.navList[i].path,
								query: {
									stage: this.$route.query.stage,
									type: this.$route.query.type,
									reportId: this.$route.query.reportId,
									traceReportId: this.$route.query.traceReportId,
									year: this.traceYear
								}
							})
						} else {
							this.$router.push({
								path: this.navList[i].path,
								query: {
									stage: this.$route.query.stage,
									type: this.$route.query.type,
									reportId: this.$route.query.reportId
								}
							})
						}
					} else {
						this.$router.push({
							path: this.navList[i].path,
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type
							}
						})
					}
				}
			},
			year: function(year, valutaionReportId, idx) {
				this.changeTraceYear(year)
				this.$router.replace({ //跳转到空白的中转页
					path: '/refresh',
					query: {
						stage: this.$route.query.stage,
						type: this.$route.query.type,
						reportId: valutaionReportId,
						traceReportId: this.$route.query.traceReportId,
						year: year
					}
				})
			},
			ok: function(i) {
				if (this.$route.query.reportId) {
					if (this.$route.query.type == 5) {
						this.$router.push({
							path: this.navList[i].path,
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId,
								traceReportId: this.$route.query.traceReportId,
								year: this.$route.query.year
							}
						})
					} else {
						this.$router.push({
							path: this.navList[i].path,
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId
							}
						})
					}
				} else {
					this.$router.push({
						path: this.navList[i].path,
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type
						}
					})
				}
			},
			cancel: function() {
				this.myAlert = false;
			},
			queryPercent: function() { //进度
				// type：报告类型：1：快速，2：专业 3:初创
				this.$post(this.projectName + 'percent/queryPercent', qs.stringify({
						type: 2,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.progressNum = res.data;
					});
			},
			initNavYear: function() {
				this.$post(this.projectName + 'traceRelation/getTraceListByTraceId', qs.stringify({
						traceReportId: this.$route.query.traceReportId
					}))
					.then((res) => {
						this.traceList = res.data.content;
					});
			},
			getBasedate: function() { //获取诊断基准日
				if (!sessionStorage.diagnoseBaseDate) {
					this.$post(this.projectName + 'baseInfo/getDiagnoseBaseDate', {})
						.then((res) => {
							sessionStorage.diagnoseBaseDate = res.data.content;
						});
				}
			}



		}
	}
</script>

<style scoped>
	.blueFont {
		color: #4393FF !important;
	}

	@media screen and (max-width: 750px) {
		.guzhiAside {
			width: 100%;
			height: 110px;
			overflow-x: scroll;
			background: #fff;
			border: none;
			margin: 0;
			padding-top: 20px;
		}

		.completion {
			display: none;
		}

		.guzhiAside ul:not(#year-container) {
			width: 700px;
			height: auto;
			left: 0;
			right: 0;
			bottom: initial;
			margin: 0;
			padding: 0 20px;
			box-shadow: none;
			display: flex;
			justify-content: space-between;

		}

		.guzhiAside>ul>li {
			width: 23%;
			height: 50px;
			line-height: 50px;
			border-radius: 25px;
			background: #e5e5e5;
			font-size: 13PX;
			text-align: center;
			cursor: pointer;
			margin-top: 0;
		}

		.guzhiAside li:not(.completion)>span {
			height: 50px;
			line-height: 50px;
			font-size: 14PX;
			position: initial;
			color: #515A6E;
		}

		.guzhiAside li a {
			color: #515a6e;
		}

		.guzhiAside li:not(.completion).liClicks a {
			color: #fff;
		}

		.guzhiAside li:not(.completion) {
			margin-top: 0;
		}

		.guzhiAside li:not(.completion).liClicks {
			height: 50px;
			border-radius: 25px;
			margin-top: 0;
			background: #0461FC;
		}

		.guzhiAside li:not(.completion).liClicks:before,
		.guzhiAside li:not(.completion).liClicks:after {
			border: none;
		}

		.guzhiAside li:not(.completion).liClicks>span {
			padding-top: 0;
			background: transparent;
			color: #fff;
		}

		::-webkit-scrollbar {
			width: 0;
		}

		.traceAside {
			height: 240px;
		}

		.traceAside ul:not(#year-container) {
			width: 800px;
			height: 210px;
		}

		.traceAside>ul>li:not(.trace_completion) {
			top: 130px;
		}

		.traceAside li.trace_completion {
			width: 95%;
			height: 110px;
			position: absolute;
			top: 0;
			background: #0461FC;
			border-radius: 0;
		}

		.traceAside li.trace_completion>div>span {
			line-height: 50px;
			font-size: 14PX;
			color: #fff;
		}

		.traceAside li.trace_completion ul.year-container {
			height: 100%;
		}

		.traceAside li.trace_completion ul.year-container li {
			width: 170px;
			height: 100%;
			background: #0461FC;
		}

		.traceAside li.trace_completion ul.year-container li>div {
			width: 40px;
			height: 50px;
			margin-left: 65px;
		}

		.blueFont {
			/* color: #2B45F4 !important; */
		}


	}
</style>
