<template>
	
	<div class="header-mian">
		<!-- {{userName}} -->
		<img src="../../assets/img/filledTuring/导航栏左侧外.png" alt="外侧下划线-心流" class="leftOutside">
		<img src="../../assets/img/filledTuring/导航栏右侧外.png" alt="外侧下划线-心流" class="rightOutside">
		<div class="headerLeft">
			<a href="http://test.flcccc.com/index.html" target="_blank">
				<img src="../../assets/img/filledTuring/logo.png" alt="logo下边框线-心流" class="headerLeftLogo">
			</a>
				<img src="../../assets/img/filledTuring/导航栏左侧内.png" alt="logo下边框线-心流" class="headerLeftLine">
		</div>
		<div class="headerCenter">
				<img src="../../assets/img/filledTuring/navcenter.png" alt="图灵估值-心流" class="headerCenterImg">
		</div>
		<div class="headerRight">
				<div class="home">
					<p @click="homePage">首页</p>
					
					<img src="../../assets/img/filledTuring/log.png" alt="登录按钮-心流" class="home-login" >
					<p :class="[logoShow?'':'hide']"><b @click="logo()">请登录 </b> |  <b @click="register()">注册 </b></p>
					<li :class="['index-my-container','user-con',valuationShow?'':'hide']">
						<div class="index-my">
								<span></span>
								<span>{{username}}</span>
						</div>
						<div class="account-sel">
							<ul>
								<li>
									<router-link tag="a" style="width: 100%;color: #018cf7;" class="my-valu-log leave"
										to="/valuationList">图灵估值报告</router-link>
								</li>
								<li><span style="width: 100%;" class="exit" @click="exit()">退出</span></li>
							</ul>
						</div>
					</li>
				</div>
				
				<img src="../../assets/img/filledTuring/导航栏右侧内.png" alt="logo下边框线-心流" class="headerRightLine">
		</div>
		
		<div class="clear"></div>
	</div>
	
</template>

<script>
import {
		mapState,
		mapMutations
	} from 'vuex'
	import $ from 'jquery'
	export default {
		data() {
			return {
				username: '',
				logoShow: false, //登录注册的显示隐藏    false 隐藏    true展示
				valuationShow: false, //我的估值显示隐藏   false 隐藏    true展示
				active: "",
				flowUrl:'',
				headerselect:true,
				flowUrl:'',
				myAlert:false,
				popButonType:'',
				markedWords:''
			}
		},
	
		mounted() {
			// var __this = this;
			// this.flowUrl = API_CONFIG.devServer.proxy['/api'].target;
			this.username = localStorage.userName ? localStorage.userName : "用户名";
			// console.log(this.username)
			if (!localStorage.userName || !localStorage.userToken) {
				this.logoShow = true;
				this.valuationShow = false;
			} else {
				this.logoShow = false;
				this.valuationShow = true;

			}
			// console.log(this.$router)
			// this.active = this.$router.history.current.path;
		},
		methods: {
			...mapMutations(['changeChannel']),
			logo: function() {
				this.$router.push({
					name: 'login',
					path: '/login'
				})
			},
			register: function(){
				this.$router.push({
					name: 'register',
					path: '/register'
				})
			},
			exit: function() {
				localStorage.removeItem("userToken");
				localStorage.removeItem("userName");
				this.$router.push({
					name: 'login',
					path: '/login'
				})
			},
			homePage:function (){
					this.$router.push({
						path: '/home',
						query: {
							stage: this.$route.query.stage ? this.$route.query.stage : '2',
							type: this.$route.query.type ? this.$route.query.type : '12',

						}
				    })
				// }
			}
		}
	}
</script>

<style scoped lang="less">
			// header宽度
			.clear {
				clear: both;
			}
			.home a{
				color: #01D9F7;
			}
			.header-mian{
				width: 80%;
				height: 12%;
				margin: auto;
				// padding-top: 1%;
				// min-width: 1000px;
				position: relative;
				// background-color: #01D9F7;
				
			}
			.headerLeft{
				width: 30%;
				height: 100%;
				float: left;
				position: relative;
			}

			.headerRight{
				width: 30%;
				float: right;
				height: 100%;
				position: relative;
			}
			.headerLeftLine{
				width: 100%;
				height: 9px;
				display: block;
				margin-top: 5px;
				position: absolute;
				bottom: 0;
			}
			.headerRightLine{
				width: 100%;
				height: 9px;
				display: block;
				position: absolute;
				bottom: 0;
				// margin-top: 15px;
				
			}
			.headerLeftLogo{
				width: 48%;
				display: block;
				// margin-top: 10px;
				position: absolute;
				bottom: 20px;
			}
			.headerCenter{
				width: 40%;
				height: 100%;
				float: left;
				position: relative;
			}
			.headerCenterImg{
				width: 100%;
				position: absolute;
				bottom: 0;
			}
			.home{
				float: right;
				// margin-top: 23px;
				// margin-bottom: 10px;
				position: absolute;
				bottom: 20px;
				right: 0;
			}
			.home p{
				// height: 30px;
				color: #01D9F7;
				font-size: 14px;
				float: left;
				margin-right: 10px;
				cursor: pointer;
				line-height: 25px;
			}
			.home li{
				// height: 30px;
				color: #01D9F7;
				font-size: 14px;
				float: left;
				margin-right: 10px;
				cursor: pointer;
			}
			.home-login{
				width: 25px;
				float: left;
				display: block;
				margin-right: 10px;
				cursor: pointer;
			}
			.leftOutside{
				width: 15%;
				height: 9px;
				position: absolute;
				left: -15%;
				bottom: 0px;
			}
			.rightOutside{
				width: 15%;
				height: 9px;
				position: absolute;
				right: -15%;
				bottom: 0px;
			}
			.headerRight-select{
				width: 100px;
				color: #fff;
				background-color: #01D9F7;
				position: absolute;
				right: 0px;
				top: 50%;
				z-index: 9999;
				padding-left: 10px;
				padding-bottom: 5px;
				cursor: pointer;
				border-radius: 5px;
				display: block;
			}
			.headerRight-select p{
				padding-top: 5px;
			}




	


	

	
	.index-reg {
		color: #0478fc;
		font-size: 0;
		/*width: 85px;*/
		width: 86px;
		height: 30px;
		border: 1px solid #0478fc;
		margin-top: 5px;
		/* border-radius: 4px; */
		line-height: 27px;
	}

	.index-my {
		cursor: pointer;
		width: 85px;
		// height: 30px;
		color: #fff;
		// margin: 5px 0;
	}

	

	.index-my>span:last-of-type {
		// max-width: 61px;
		// line-height: 30px;
		overflow: hidden;
		// text-overflow: ellipsis;
		white-space: nowrap;
		color: #01D9F7;
		
	}

	.index-my>span {
		float: left;
		line-height: 25px;
	}

	.index-reg>a {
		margin: 0 8px;
	}

	.account-sel {
		width: 120px;
		display: block;
		text-align: center;
		border-top: 0;
		background: #fff;
		border-radius: 5px;
		display: none;
		position: absolute;
		top: 90%;
		z-index: 999;
	}

	.account-sel>ul>li {
		line-height: 30px;
		cursor: pointer;
		text-align: left;
		margin-left: 16px;
		box-sizing: content-box;
		color: #018cf7;
	}

	.account-sel>ul>li:first-child {
		padding-top: 6px;
		color: #018cf7;
		// color: #fff;
	}

	.account-sel>ul>li:last-child {
		padding-bottom: 6px;
	}

	// .account-sel>ul>li:hover a,
	// .account-sel>ul>li:hover span,
	// .header-one-right>ul>li:hover>a.leave {
	// 	color: #0478fc;
	// }

	.account-sel>ul>li>span {
		max-width: 90px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	// .index-my-container:hover .index-my>span:last-of-type {
	// 	color: #4393ff;
	// }

	.index-my-container:hover .account-sel {
		display: block !important;
	}

	.header-one-right>ul>li.active>a {
		color: #282828;
	}

	// .header-one-right>ul>li.active>a.leave {
	// 	color: #0478fc;
	// }

	// .header-one-right>ul>li.active:hover a {
	// 	color: #0478fc;
	// }

	// .header-one-right>ul>li.nav-item:hover .product_servicefont span {
	// 	color: #4393FF;
	// }

	.header-one-right>ul>li.nav-item.active>i {
		display: inline-block;
	}

	.header-one-right>ul>li.nav-item:hover .product_sel>ul>li>a.leave {
		color: #000;
	}

	.header-one-right>ul>li.nav-item {
		height: 72px;
		line-height: 72px;
	}

	.header-one-right>ul>li:not(.nav-item) {
		margin-top: 17px;
	}

	.header-one-right>ul>li:not(.nav-item):last-of-type {
		line-height: 1;
	}

	#aliyun-module {
		// display: none;
	}
</style>
