<template>
    <div class="formMain">
     <div class="formBodyPositioning">
       <Header />
       <div class="formWidth">
         <div class="formMainModule">
			<div class="headCategory">
					<div class="statusBar">
						<div :class="['payStatus',state_payment?'flexible':'']" @click="state_payment=!state_payment">
							<p>{{pay_status[state_payment_index].state}}<i> > </i></p>
							<div v-if="state_payment" class="state_bg">
								<!-- v-on:click.stop阻止事件冒泡  避免触发父级的点击事件 -->
								<span :class="{select:index==state_payment_index}" v-for="(item, index) in pay_status"
									 v-on:click.stop="payment(index)">{{item.state}}</span>
							</div>
						</div>
						<div class="search">
							<input type="text" placeholder="报告简称/全称" v-model="companyName" @focus="foucs" @blur="blur">
							<img :src="searchIcon" alt="搜索-心流" @click="search()">
						</div>
					</div>
			</div>
           <div class="formInfomationTitle">
             估值报告列表
           </div>
           <div class="relevantQuestionMain">
                <div class="relevantQuestion">
					<div class="main information">
					<div class="content">
						<ul class="table_title">
							<li style="width:22%;">企业名称</li>
							<li style="width:17%;">所属行业</li>
							<li style="width:16%;">估值时间</li>
							<li style="width:12%;">金额</li>
							<!-- <li style="width:13%;">类型</li> -->
							<li style="width:12%;">状态</li>
							<li style="width:20%;">操作</li>
						</ul>
						<div class="none-show" v-if="noData">
							<img class="no-data-default" src="../../assets/img/filledTuring/暂无估值记录.png" />
							<p>暂无您的估值记录</p>
						</div>
						<ul class="table_item" v-if="!noData">
							<li v-for="(item, index) in valuationList" :data-reportid="item.id" :data-type="item.type"
								:data-stage="item.stage" :data-versions="item.versions">
								<div class="company-name" title="" style="width:22%;">{{item.name?item.name:''}}</div>
								<div class="industryContent" style="width:17%">{{item.industryName?item.industryName:''}}</div>
								<div class="valution-time" style="width:16%">{{item.createTime?item.createTime:''}}</div>
								<div class="valution-money" style="width:12%">{{item.payAmount?'￥'+item.payAmount:''}}</div>
								<!-- <div class="valuation-type" style="width:13%">{{item.type==12?'图灵估值':""}}
								</div> -->
								<div class="audit" style="width:12%">
									<span class="examineState" v-if="item.payStatus=='0'">待付款</span>
									<span class="examineState examineing"
										v-else-if="item.payStatus=='1'&&item.auditStatus=='2'">审核中</span>
									<span class="examineState isExamine"
										v-else-if="item.payStatus=='1'&&item.auditStatus=='1'">审核通过</span>
									<span class="examineState noExamine"
										v-else-if="item.payStatus=='1'&&item.auditStatus=='0'">审核未通过</span>
								</div>
								<div class="operationIcon" v-if="item.payStatus=='0'|| !item.payStatus">
									<a class="edit"
										@click.stop="modification(item.id,item.type,item.stage,item.versions,item.auditStatus)"
										v-preventReClick="5000">修改</a>
									<a class="del" @click="del(item.id,index)">删除</a>
								</div>
								<div class="operationIcon" v-else-if="item.payStatus=='1'&&item.auditStatus=='2'">
									<a
										@click.stop="getInfo(item.id,item.type,item.stage,item.auditStatus,item.versions)">查看信息</a>
								</div>
								<div class="operationIcon" v-else-if="item.payStatus=='1'&&item.auditStatus=='1'">
									<a
										@click.stop="getInfo(item.id,item.type,item.stage,item.auditStatus,item.versions)">查看信息</a>
									<a class="checkReport" target="_blank"
										@click="checkSummaryPage(item.id,item.type,item.versions)">查看报告</a>
								</div>
								<div class="operationIcon" v-else-if="item.payStatus=='1'&&item.auditStatus=='0'">
									<a class="edit"
										@click.stop="modification(item.id,item.type,item.stage,item.versions,item.auditStatus)"
										v-preventReClick="5000">修改</a>
									<a
										@click.stop="getInfo(item.id,item.type,item.stage,item.auditStatus,item.versions)">查看信息</a>
								</div>
							</li>
						</ul>
				<!--分页-->
				<div class="pageBox" v-if="!noData">
					<Page :current="current" :total="total" @on-change="handleChange"></Page>
				</div>
			</div>
		</div>
                </div>
           </div>
         </div>
       </div>
       <Footer />
	   <dialogBar v-model="myAlert" :type="popButonType" :title='markedWords' content="" @delTypeJump="clickdelTypeJump()"></dialogBar>
	   <!-- <Modal v-model="myAlert" class-name="vertical-center-modal del" @on-ok="ok()">
			<div>
				<p>确定要删除吗？</p>
				<p>删除后无法恢复这条估值记录</p>
			</div>
		</Modal> -->

		<!-- <Modal v-model="diagnosisAlert" title="提示:" class-name="vertical-center-modal newDiagnosis">
			<div>
				<p>企业价值诊断已全新改版，财务分析更全面（新增7个维度），请填写新版价值诊断</p>
			</div>
			<div slot="footer" class="ivu-sure" @click="submitDialog">确定</div>
		</Modal> -->
     </div>
   </div>
  </template>
 <script>
  // 头部组件引入
  import Header from '../../common/header/header.vue';
  // 尾部组件引入
  import Footer from '../../common/footer/footer.vue';
 import qs from 'qs'
 import axios from 'axios';
 import {
	 mapState,
	 mapMutations
 } from 'vuex'
 import dialogBar from './popue.vue'
 import $ from 'jquery'

 export default {
	 data() {
		 return {
			 searchIcon: require('../../assets/img/filledTuring/suosou.png'),
			 state_payment: false, //支付状态  true显示3中支付状态  默认全部状态
			 state_payment_index: 0,
			 pay_status: [{
				 state: '全部状态',
			 }, {
				 state: '未支付',
			 }, {
				 state: '已支付',
			 }],
			 noData: true, //默认展示
			 statusCode: 3, // 支付状态  全部3  未支付0  已支付1
			 valuationList: [],
			 current: 1,
			 total: 0,
			 pageSize: 5,
			 companyName: '',
			 myAlert: false,
			 reportId: '',
			 liIndex: 0,
			 diagnosisAlert: false, //跳转新版诊断的提示
			 loading: true,
			 scrollNum: 1,
			 markedWords:"",
			 popButonType:"delType",
			 versions:''
		 }
	 },
	 components: {
		 Header,
		 Footer,
		 dialogBar
	 },
	 computed: {
		 ...mapState(['traceYear'])
	 },
	 created() {
		 this.list(1, 3);
	 },
	 mounted() {
		 sessionStorage.removeItem('diagnoseBaseDate'); //清空诊断基准日
		 // 监听滑动到底部
		 var that = this;
	 },
	 methods: {
		 ...mapMutations(['changeTraceYear']),
		 payStatus: function() {
			 this.state_payment = true;
			 for (var i = 0; i < this.pay_status.length; i++) {
				 this.pay_status[i].show = "";
			 }
		 },
		 payment: function(index) {
			 this.scrollNum = 1;
			 this.current = 1;
			 this.state_payment = !this.state_payment;
			 this.state_payment_index = index;
			 this.statusCode = index == 0 ? 3 : index == 1 ? 0 : 1;
			 this.valuationList = [];
			 this.list(this.current, this.statusCode, this.companyName)

		 },
		 list: function(pageNum, payStatus, equipment) { // equipment 1代表pc端   2代表移动端
			
			 this.loading = true;
			 var this_ = this;
			 var url = '';
			 url = this.domain.ajaxUrl +'valuationReport/findValuationReport'
			 this.$post(url, qs.stringify({
					 pageNum: pageNum, //当前页
					 payStatus: payStatus, //全部3  未支付0  已支付1
					 pageSize: 5, //每页条数
					 tab: 8, //图灵估值
					 companyName: this.companyName //公司名称
				 }))
				 .then((res) => {
					 if (res && res.data.content.records.length > 0) {
						 this.noData = false;
						 this.total = res.data.content.totalPage * 10;
						 if (equipment == 2) {
							 res.data.content.records.forEach(function(item, i) {
								 this_.valuationList.push(item)
							 })
						 } else {
							 this.valuationList = res.data.content.records;
						 }
					 } else {
						 this.noData = true;
						 if (equipment == 2) {
							 this.loading = false;
						 }
					 }
				 });
		 },
		 handleChange: function(page) {
			 this.state_payment = false;
			 this.current = page;
			 this.list(page, this.statusCode)
		 },
		 foucs: function() {
			//  this.searchIcon = require("@/assets/img/guzhi/suosou2.png");
		 },
		 blur: function() {
			//  this.searchIcon = require("@/assets/img/guzhi/suosou.png");
		 },
		 search: function() {
			// 搜索后页码重置回第一页  wangdi修复
			this.current = 1
			this.list(this.current, this.statusCode);
			
			 
		 },
	
		
		// 修改跳转首页
		 modification: function(reportId, type, stage, versions, auditStatus) {
			     sessionStorage.removeItem("isHistoryCache")
				 this.$router.push({
					 path: '/home',
					 query: {
						 stage: stage,
						 type: type,
						 reportId: reportId
					 }
				 })

		 },
		//  查看信息  跳转
		 getInfo: function(reportId, type, stage, auditStatus, versions) {
			 this.versions = versions ? versions : 1;
				//  this.$router.push({
				// 	 path: '/examineAuditStatus',
				// 	 query: {
				// 		 stage: stage,
				// 		 type: type,
				// 		 reportId: reportId,
				// 		 auditStatus: auditStatus
				// 	 }
				//  })
				let routeData = this.$router.resolve({ 
					path: '/examineAuditStatus',
					query: {
						 stage: stage,
						 type: type,
						 reportId: reportId,
						 auditStatus: auditStatus
					 }
				});
 				window.open(routeData.href, '_blank');

		 },
		//  删除  报告
		 del: function(id, index) { 
			 this.myAlert = true;
			 this.markedWords="确定要删除吗  ？删除后无法恢复这条估值记录";
			 this.popButonType="delType"
			 this.reportId = id;
			 this.liIndex = index;
		 },
		 clickdelTypeJump: function() {
			 this.$post('flow/valuationReport/deleteValuationReportById', qs.stringify({
					 reportId: this.reportId
				 }))
				 .then((res) => {
					 if ($(".content>ul.table_item>li").length = 0) {
						 this.noData = true;
					 }
					 this.list(this.current, this.statusCode)
				 });
		 },
		
		 checkSummaryPage: function(reportId, type, versions) { //查看报告（跳转到总结页）
			 var query = {
				 reportId: reportId,
				 type: type,
				 versions: versions ? versions : 2
			 };
			//  if (type == 12) {
			// 	 this.$router.push({
			// 		 path: '/turingReport',
			// 		 query: query
			// 	 })
			//  } 

			 let routeData = this.$router.resolve({ 
					path: '/turingReport',
					query: query
			});
 			window.open(routeData.href, '_blank');
		 }


	 }
 }
</script>
  
  
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="less">
	input::-webkit-input-placeholder {
		color: #fff;
		font-size: 12px;
	}

  .main>p.title {
	  position: relative;
	  height: 88px;
	  line-height: 88px;
	  margin-top: 50px;
	  border-color: #e6e6e6;
  }

  .information p.title>img {
	  width: 38px;
	  height: 38px;
	  vertical-align: middle;
	  margin-right: 13px;
  }

  .information p.title>span {
	  color: #282828;
	  font-size: 18px;
	  font-weight: bold;
  }


  /* .main .content {
	  background: #fff;
	  height: 76%;
  } */

   .content>label {
	  display: inline-block;
	  width: 120px;
	  height: 38px;
	  line-height: 38px;
	  border-radius: 6px;
	  background: #4393FF;
	  cursor: pointer;
  }

   .content>label img {
	  width: 17px;
	  height: 17px;
	  margin: 10px 3px 0 18px;
  }


  .content .addGuZhi {
	  color: #fff;
	  text-align: center;
	  vertical-align: top;
	  font-size: 16px;
  }

   .content .headCategory {
	  height: 63px;
	  border-top: 1px solid #e6e6e6;
	  border-bottom: 1px solid #e6e6e6;
  }

   .content .headCategory .reportCatalog {
	  float: left;
	  line-height: 63px;
  }

   .content .headCategory .reportCatalog span {
	  font-size: 16px;
	  margin-right: 31px;
	  cursor: pointer;
  }

   .content .headCategory .reportCatalog span:last-child {
	  margin-right: 0;
  }

   .content .reportCatalog span.select {
	  border-bottom: 3px solid #4393FF;
	  color: #4393FF;
  }

	.headCategory .statusBar {
	   width: 40%;
	   height: 60px;
	   /* background-color: #009933; */
	  font-size: 14px;
	  position: relative;
	  /* background-color: #009933; */
	  position: absolute;
	  right: 0px;
	  /* overflow: hidden; */
  }

    .headCategory .statusBar .payStatus {
	  /* width: 110px; */
	  /* background: #fff; */
	  /* padding: 0 21px; */
	  cursor: pointer;
	  position: absolute;
	  top: 15px;
	  right: 52%;
	  float: left;
	  font-size: 12px;
	  /* border: 1px solid #fff; */
	  padding-top: 3px;
  }
  .headCategory .statusBar .flexible{
	border: 1px solid #fff;
  }
    .headCategory .statusBar .payStatus p {
	  color: #00E4FF;
	  padding: 0px 10px;
	  margin-top: 6px;
  }

   .headCategory .statusBar .payStatus p i {
	  transform: rotate(90deg);
	  -webkit-transform: rotate(90deg);
	  -moz-transform: rotate(90deg);
	  -ms-transform: rotate(90deg);
	  -o-transform: rotate(90deg);
	  color: #fff;
	  margin-left: 7px;
  }

   .headCategory .statusBar .payStatus span {
	  margin: 2px 0;
	  display: block;
	  color: #fff;
	  padding: 0px 10px;
  }

   .headCategory .statusBar .payStatus span.select {
	  color: #00E4FF;
	  padding: 0px 10px;
  }

   .content .headCategory .statusBar .flexible {
	  border: 1px solid rgba(202, 202, 202, 1);
	  top: 14px;
	  padding: 10px 21px;
  }

   .content .headCategory .statusBar .flexible p i {
	  transform: rotate(-90deg);
	  -webkit-transform: rotate(-90deg);
	  -moz-transform: rotate(-90deg);
	  -ms-transform: rotate(-90deg);
	  -o-transform: rotate(-90deg);
  }

    .headCategory .statusBar .search {
	  width:40%;
	  height: 36px;
	  border: 1px solid #FFFFFF;
	  line-height: 36px;
	  /* position: absolute;
	  top: 20px;
	  right:30px; */
	  float: right;
	  margin-right: 8%;
	  padding: 0px 10px;
	  margin-top: 15px;
	  position: relative;
  }
  .headCategory .statusBar .search input{
	height: 100%;
	width: 85%;
	background-color: transparent;
	color:#FEFEFE;
	font-size: 12px;
  }

    .headCategory .statusBar .search img {
	  width: 15px;
	  position: absolute;
	  top: 10px;
	  right: 5px;
	  cursor: pointer;
  }

  ul.table_title {
	  height: 45px;
  }

  ul.table_title>li {
	  float: left;
	  height: 45px;
	  line-height: 45px;
	  text-align: center;
	  font-size: 14px;
	  color: rgba(255, 255, 255, 1);
	  font-weight: bold;
	  /* background-color: #4393FF; */
	  white-space: nowrap;
	  text-overflow: ellipsis;
	  overflow: hidden;
	  border-left: 1px solid #fff;
  }
  ul.table_title>li:nth-child(1){
		border-left:none;
  }

  ul.table_item>li>div {
	  float: left;
	  height: 50px;
	  line-height: 50px;
	  text-align: center;
	  font-size: 12px;
	  font-weight: 400;
	  color:#fff;
	  white-space: nowrap;
	  text-overflow: ellipsis;
	  overflow: hidden;
  }

  ul.table_item>li {
	  border-bottom: 1px solid #fff;
  }

  ul.table_item>li .company-name {
	  width: 18%;
	  padding: 0 1%;
  }

  ul.table_item>li .industryContent {
	  width: 20%;
	//   text-align: left;
  }

  ul.table_item>li .valution-money {
	  width: 5%;
	  text-align: center;
  }

  img.no-data-default {
	  display: block;
	  width: 26%;
	  margin: 0 auto;
	  margin-top: 7%;
  }

  .none-show>p {
	  font-size: 16px;
	  font-family: STHeitiSC-Light;
	  color: #fff;
	  text-align: center;
	  margin-top: 20px;
  }

  .main ul.table_title {
	  margin-top: 30px;
	  background:url("../../assets/img/filledTuring/liebiaobg.png");
	  background-size: 100% 100%;
  }

  .main ul.table_item li {
	  height: 50px;
	  overflow: hidden;
  }

  .main ul li.liSelect {
	  /*border: 1px solid #FFC600;*/
	  box-shadow: 0 0 10px 3px #cccccc70;
  }

  .main ul li.liSelect .baseName {
	  color: #4393FF;
  }

  .main ul li .baseShow {
	  display: inline-block;
	  min-height: 95px;
	  width: 85%;
  }

  .main ul li .baseShow .baseName {
	  min-width: 36%;
	  height: 37px;
	  line-height: 37px;
	  font-size: 16px;
	  font-weight: bold;
	  margin: 10px 0;
  }

  .examineState {
	  /* width: 90px;
	  height: 30px;
	  line-height: 30px;
	  border: 1px solid #4393FF;
	  color: #4393FF;
	  border-radius: 15px; */
	  color:#47ABFF;
  }

  .isExamine {
	  /* border: 1px solid #009933; */
	  color: #00E4FF;
  }

  .noExamine {
	  /* border: 1px solid #EB0000; */
	  color: #FF0000;
  }

  .examineing {
	  /* border: 1px solid #ECC641; */
	  color: #FFD821;
  }

  .main ul li .operationIcon {
	  display: inline-block;
	  vertical-align: middle;
	  width: 18%;
	  text-align: right;
	//   float: right;
  }

  .main ul li .operationIcon a {
	  width: 43%;
	  height: 20px;
	  line-height: 20.5px;
	  text-align: center;
	  background: #47ABFF;
	  border-radius: 4px;
	  color: #fff;
	  /* margin-left: 20px; */
	  cursor: pointer;
	
  }
  .main ul li .operationIcon a:nth-child(2){
	margin-left: 7%;
  }
  .main ul li .operationIcon a.edit {
	  background: #F9A400;
  }

  .main ul li .operationIcon a.del {
	  background: #FF0000;
  }

  .main ul li .operationIcon a:first-of-type {
	  margin-left: 0;
  }

  .main ul li .operationIcon img {
	  margin-right: 15%;
	  cursor: pointer;
  }

  .pageBox {
	  text-align: center;
	  margin-top: 40px;
  }

  .loading {
	  text-align: center;
  }

  .loading img {
	  width: 90px;
	  height: 70px;
	  vertical-align: middle;
  }
  .state_bg{
	background-color: #000082;
	padding-bottom: 10px;
	padding-top: 3px;
  }

  /deep/ .ivu-page-next{
	background-color:transparent;
  }

  /deep/ .ivu-page-next:hover{
	border: 1px solid #00E4FF;
  }
  /deep/ .ivu-page-next:hover .ivu-icon-ios-arrow-forward:before{
	color:  #00E4FF;
  }
  /deep/ .ivu-page-prev{
	background-color:transparent;
  }
  /deep/ .ivu-page-prev:hover{
	border: 1px solid #00E4FF;
  }
  /deep/ .ivu-page-prev:hover .ivu-icon-ios-arrow-back:before{
	color:  #00E4FF;
  }
  /deep/ .ivu-page-item{
	background-color:transparent;
  }
  /deep/ .ivu-page-item:hover{
	border:1px solid #00e4ff;	
  }
  /deep/ .ivu-page-item:hover a{
	color: #00E4FF;	
  }
 
	/deep/ .ivu-page-item-active{
		border:1px solid #00e4ff;
		color:#00e4ff;
	}
	
	/deep/ .ivu-icon-ios-arrow-forward:before{
		color: #fff;
	}/deep/ .ivu-icon-ios-arrow-back:before{
		color: #fff;
	}
	
	/deep/.ivu-page-item a{
		color: #fff;
	}
	/deep/ .ivu-page-item-active a{
		color:#00E4FF;
	}
	/deep/ .ivu-page-item-jump-next a{
		width: 100%;
	}
	/deep/ .ivu-page-item-jump-prev:after{
		margin-top: -27px;
	}
	/deep/  .ivu-page-item-jump-next:after{
		margin-top: -27px;
	}
	
//   /deep/ .ivu-page-item-active a, .ivu-page-item-active:hover a{
// 	color: #fff;
//   }
 


</style>
  