<template>
	<div class="formMain">
		<div class="formBodyPositioning">
			<Header />
			<div class="formWidth">
				<div class="formMainModule">
					<div class="formInfomationTitle">估值报告</div>
					<PaymentInformation />
					<div class="relevantQuestionMain">
						<Navigation :nav="navList" />
						<div class="relevantQuestion major-balance">
							<span class="unit">单位：元</span>
							<table class="table" id="tableOne" border="0" style="border-collapse: collapse;">
								<thead>
									<tr class="noInput">
										<th>项目</th>
										<th v-for="(item, index) in yearList">{{item}}</th>
									</tr>
								</thead>
								<tbody>
									<!-- 隐藏占位 -->
									<tr class="trTwo tipTerm" style="visibility: hidden;">
										<td></td>
									</tr>
									<tr class="trOne tipTerm" data-class="income">
										<td>营业收入<span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="profit.income[i]" />
											<span class="showInput">
												{{judgingCondition('income',i)?formatCurrency.formateNum(profit.income[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo tipTerm" data-class="cost">
										<td style="padding-left: 10px;">减:营业成本<span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="profit.cost[i]" />
											<span class="showInput">
												{{judgingCondition('cost',i)?formatCurrency.formateNum(profit.cost[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo tipTerm" data-class="taxAdditional">
										<td>税金及附加<span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="profit.taxAdditional[i]" />
											<span class="showInput">
												{{judgingCondition('taxAdditional',i)?formatCurrency.formateNum(profit.taxAdditional[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo tipTerm" data-class="saleCost">
										<td>销售费用 <span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="profit.saleCost[i]" />
											<span class="showInput">
												{{judgingCondition('saleCost',i)?formatCurrency.formateNum(profit.saleCost[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo tipTerm" data-class="manageCost">
										<td>管理费用<span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="profit.manageCost[i]" />
											<span class="showInput">
												{{judgingCondition('manageCost',i)?formatCurrency.formateNum(profit.manageCost[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo" data-class="researchCost">
										<td>研发费用</td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="profit.researchCost[i]" />
											<span class="showInput">
												{{judgingCondition('researchCost',i)?formatCurrency.formateNum(profit.researchCost[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo tipTerm" data-class="financeCost">
										<td>财务费用<span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="profit.financeCost[i]" />
											<span class="showInput">
												{{judgingCondition('financeCost',i)?formatCurrency.formateNum(profit.financeCost[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trFour tipTerm" data-class="interestCost">
										<td style="padding-left:40px">其中:利息支出 <span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="profit.interestCost[i]" />
											<span class="showInput">
												{{judgingCondition('interestCost',i)?formatCurrency.formateNum(profit.interestCost[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trOne" data-class="businessProfit">
										<!-- 复制的营业加:营业外收入	 -->
										<td>营业利润<span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="profit.businessProfit[i]"/>
											<span class="showInput">
												{{judgingCondition('businessProfit',i)?formatCurrency.formateNum(profit.businessProfit[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trOne" data-class="totalProfit">
										<td>利润总额<span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="profit.totalProfit[i]"/>
											<span class="showInput">
												{{judgingCondition('totalProfit',i)?formatCurrency.formateNum(profit.totalProfit[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo tipTerm" data-class="incomeTax">
										<td>减:所得税费用 <span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="profit.incomeTax[i]" />
											<span class="showInput">
												{{judgingCondition('incomeTax',i)?formatCurrency.formateNum(profit.incomeTax[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trOne" data-class="netProfit">
										<td>净利润<span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="profit.netProfit[i]">
											<span class="showInput">
												{{judgingCondition('netProfit',i)?formatCurrency.formateNum(profit.netProfit[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trOne" data-class="editda">
										<td>折旧与摊销<span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="profit.editda[i]"/>
											<span class="showInput">
												{{judgingCondition('editda',i)?formatCurrency.formateNum(profit.editda[i],2):'-'}}
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	// 头部组件引入
	import Header from '../../common/header/header.vue';
	// 尾部组件引入
	import Footer from '../../common/footer/footer.vue';
	import '../../assets/css/common.css';
	import PaymentInformation from '../../common/getInfo/paymentInformation.vue';
	import Navigation from '../../common/getInfo/navigation.vue';
	import qs from 'qs'

	export default {
		data() {
			return {
				navList: ['软性指标', '资产负债表', '利润表', '审核状态', '基本信息'],
				profit: {
					income: [],
					cost: [],
					taxAdditional: [],
					saleCost: [],
					manageCost: [],
					researchCost: [],
					financeCost: [],
					interestCost: [],
					businessProfit: [],
					totalProfit: [],
					incomeTax: [],
					netProfit: [],
					goingConcernNetProfit: [],
					editda: []
				},
				yearList:[],
				projectName: ''
			}
		},
		components: {
			Header,
			Footer,
			PaymentInformation,
			Navigation
		},
		mounted() {
			if (this.$route.query.type == 12) {
				this.projectName = this.domain.turingProject;
			}
			this.init()

		},
		methods: {
			judgingCondition: function(key, i, index) {
				if (this.profit[key][i] === 0) {
					return true
				} else if (this.profit[key][i] != 0 && this.profit[key][i] != '-') {
					return true
				} else {
					return false
				}
			},
			init: function() {
				this.$post(this.projectName + 'auditInfoShow/qryFinanceInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						console.log(res.data)
						var this_ = this;
						var profitKey = Object.keys(this.profit); //获取资产负债表对象的 key
						this.yearList = res.data.content.profitInfo.year;
						if (res.data.content.profitInfo.profit) {
							var returnprofitKey = Object.keys(JSON.parse(res.data.content.profitInfo
								.profit)); //后台数据返回的资产负债表对象的 key
							profitKey.forEach((elem, index) => {
								returnprofitKey.forEach((item, i) => {
									this_.yearList.forEach((yearItem, y) => {
										if (elem == item) {
											this_.profit[elem][y] = JSON.parse(
												res.data.content.profitInfo.profit)[
												elem][y];
										} else if (!this_.profit[elem]) {
											if (this_.yearList.length == 2) {
												this_.profit[elem] = ['', '']
											} else if (this_.yearList.length == 3) {
												this_.profit[elem] = ['', '', '']
											}
										}
									})
								})
							})
							console.log(this.profit)
						}

					});
			}

		}
	}
</script>

<style scoped src="../../assets/css/myGuZhi.css"></style>
<style scoped>
	span.unit {
		margin-top: 0;
	}

	.relevantQuestionMain {
		height: 79%;
		margin-top: 3%;
	}
	
	@media screen and (max-height:800px){
		.relevantQuestionMain{
			margin-top: 5%;
		}
	}
</style>
