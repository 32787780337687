<template>
    <div class="formMain">
     <div class="formBodyPositioning">
       <Header />
       <div class="formWidth" style="height:58%">
         <div class="formMainModule">
           <div class="formInfomationTitle" style="padding-top: 0.1%;">
             估值报告
           </div>
           <div class="relevantQuestionMain" style="margin-top:0px">
                <div class="relevantQuestion">
                        <div class="paybg">
                            <div class="payLeft">
                                <img src="../../assets/img/filledTuring/zhifutu.png" alt="图灵估值支付">
                            </div>
                            <div class="payRight">
                                <div class="paymia">
                                    <div class="valuationpPrice">
                                        <img src="../../assets/img/filledTuring/tulingguzhi.png">
                                        <span>图灵估值</span>
                                        <p><i>￥</i>{{price}}/份</p>
                                    </div>  
                                    <div class="valuationEssentials">
                                        <li><i></i>完整的财务预测</li>
                                        <li><i></i>专业估值诊断与建议</li>
                                        <li><i></i>公司基本情况分析</li>
                                        <li><i></i>估值原理说明</li>
                                        <li><i></i>相对估值和绝对估值法相结合</li>
                                        <li><i></i>一键生成报告</li>
                                        <li><i></i>可比公司分析</li>
                                    </div>
                                    <div class="payButton" @click="pay">
                                        立即支付
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
           </div>
         </div>
       </div>

      

       <!-- 付款成功提示框 -->
       <Modal v-model="paySuccess" class-name="vertical-center-modal paySuccess" @on-visible-change="modelPaySuccess"
			:mask-closable="false">
			<img src="../../assets/img/filledTuring/success.png" alt="付款成功-心流">
			<div class="hint-success">
				<p>恭喜您，付款成功！估值报告审核中，请耐心等待</p>
				<p>审核人员会在一个工作日内反馈审核结果。</p>
				<p>如有疑问请拨打客服电话：+86 (10)8444-6599</p>
			</div>
			<div class="hint-sure" @click="jumpLists">确定</div>
		</Modal>

       <!-- 弹出填写发票 -->
       <Modal v-model="payment" class-name="vertical-center-modal payment" @on-visible-change="modelSaveclose"
			:mask-closable="false">
			
			<div class="hint">
				<!--报告类型-->
				<div class="type report_style">
					<img src="../../assets/img/filledTuring/价格@2x.png" alt="线上估值-心流" />
					<p>报告价格： </p> 
                    <span><i>￥</i>{{price}}</span>
				</div>
				
				<!--是否开发票-->
				<div class="type is_invoice">
					<img src="../../assets/img/filledTuring/发票@2x.png" alt="线上估值的发票-心流" />
					<p>开发票:</p>
					<div class="radio yes" data-invoiceFlag="1">
						<input type='radio' id="yes-radio" name="" :checked="!noInvoice?'checked':''"
							@click="yesRadio" />
						<label for="yes-radio">是</label>
					</div>
					<div class="radio no" data-invoiceFlag="1">
						<input type='radio' id="no-radio" name="" :checked="noInvoice?'checked':''" @click="noRadio" />
						<label for="no-radio">否</label>
					</div>
				</div>
				<!--发票信息-->
				<div class="check_info" v-if="!noInvoice">
					<div class="check_title">
						<span></span>
						<span>电子普通发票</span>
						<span>（如需纸质发票请联系客服，客服电话：+86 (10)8444-6599）</span>
					</div>
					<div class="needed invoice-title">
						<span>发票抬头</span>
						<input type="text" name="" id="invoice-title" value="" v-model="invoiceTitle" />
					</div>
					<div class="needed invoice-title">
						<span>纳税人识别号</span>
						<input type="text" name="" id="taxpayer-num" value="" v-model="taxpayerNum" />
					</div>
					<div class="needed invoice-title">
						<span>电子邮件</span>
						<input type="text" name="" id="email" value="" placeholder="推荐使用QQ邮箱" v-model="email" />
					</div>
					<div class="needed invoice-title">
						<span>手机号</span>
						<input type="text" name="" id="mobile" value="" placeholder="" v-model="phoneNo" />
					</div>
				</div>
                <div class="pay_now" @click="saveInvoiceInfo">立即付款</div>
			</div>
		</Modal>

        <!-- 弹出支付码 -->
        <Modal v-model="paymentCode" class-name="vertical-center-modal payment" :mask-closable="false"
                @on-visible-change="codeAlert">
                <!-- <p slot="header">
                    <img src="../../assets/img/guzhi/icon_pay.png" alt="付款-心流">
                    <span>付款</span>
                </p> -->
                <img src="../../assets/img/filledTuring/erweima.png" class="erweima">
                <div class="qrcode" ref="qrCodeUrl">
                    
                </div>
                <div class="payPrice">
                    ￥{{price}}元
                </div>
                <p class="selectionMethod">使用支付宝、微信扫码支付</p>
                <div class="payIcon">
                    <img src="../../assets/img/guzhi/icon_alipay.png">
                    <img src="../../assets/img/guzhi/icon_wechat.png">
                </div>
                <div slot="footer"></div>
		</Modal>
       <Footer />
       <!-- 全局加载 -->
       <div class="spin-container"  v-if="ishow">
            <Spin fix></Spin>
        </div>
     </div>
   </div>
  </template>
  <script>
  // 头部组件引入
  import Header from '../../common/header/header.vue';
  // 尾部组件引入
  import Footer from '../../common/footer/footer.vue';
  import qs from 'qs';
  import axios from 'axios';
  import {
      mapState,
      mapMutations
  } from 'vuex';
  import QRCode from 'qrcodejs2'
//   import dialogBar from './paypopue.vue'
  import API_CONFIG from "../../../vue.config.js"
  import $ from 'jquery'

  export default {
      data() {
          return {
              finish: false, //必填项是否全部填写完成
              price: '', //价格
              auditStatus: null, //审核状态0：审核不通过 ， 1：审核通过 ， 2：审核中
              isTestAccount: null, //测试账号免支付
              title: '',
              tpPageFlag: false, //是否已经存在继续填写的页面(价值追踪)
              hrefHtml: '', //未填写完的跳转路径
              checkObj: [],
              resubmitAlert: false, //重新提交审核弹框
              paySuccess: false, //付款成功后的弹框
              payment: false, // 付款信息弹框
              paymentCode: false, //支付的二维码弹框
              noInvoice: false, //false 开发票   true 不开发票
              invoiceTitle: '', //发票抬头
              taxpayerNum: '', //纳税人识别号
              email: '', //电子邮件
              phoneNo: '', //手机号
              invoiceId: '', //发票id
              timer: null,
              projectName: '',
              ishow:true,
              popButonType:'success',
          }
      },
      components: {
          Header,
          Footer,
        //   dialogBar
      },
      mounted() {
          if (this.$route.query.type == 12) {
              this.projectName = this.domain.turingProject;
          }
          //全局加载中
          this.ishow = true
          this.pageModify('false');
          //获取价格
          this.getReportPrice();
          //判断是否是测试账号
        //   this.noPayment();

          this.$Message.config({
              top: 200
          });

      },
      methods: {
          ...mapMutations(['pageModify']),
          getReportPrice: function() { //获取价格

                    // 获取正式号测试号
                    this.isTestAccount = localStorage.getItem("isTestAccountKey");
                    // console.log(this.isTestAccount)

                    this.$post(this.domain.ajaxUrl + 'pay/getReportPrice', qs.stringify({
                      reportId: this.$route.query.reportId
                    }))
                    .then((res) => {
                        this.ishow = false
                        this.price = res.data.content;
                    });
          },
          pay: function() {
                //测试账号免支付
                if (this.isTestAccount=='true') { 
                    this.noPayment();
                } else {
                    // 正式号支付流程
                    if(this.price == '0' && this.isTestAccount=='false'){ //正式号生成价格为0 不走支付流程
                        this.noPayment();
                        // console.log("111")
                    }else{ 
                        this.payment = true;
                        this.queryInvoice();

                    }
                }
          },
          noPayment: function(status) { //测试账号免支付   isTestAccount = true
                  this.$post(this.domain.ajaxUrl + 'pay/testAccountPay', qs.stringify({
                      reportId: this.$route.query.reportId
                  }))
                  .then((res) => {
                     // 判断是否  默认加载 或 点击支付调用
                    //  if(status == 1){
                        this.popButonType = 'success'
                        this.paySuccess = true;
                    //  }
                    //  this.isTestAccount = res.data.content.isTestAccount
                  });
          },
         
       
          yesRadio: function() { //开发票
              this.noInvoice = false;
              $('.ivu-modal').removeClass('ivu-active-bg'); //切换背景图
          },
          noRadio: function() { //不开发票
              this.noInvoice = true;
              $('.ivu-modal').addClass('ivu-active-bg'); //切换背景图
          },
          queryInvoice: function() { //查询发票信息
            //   this.$Spin.show(); //全局加载中
              this.$post(this.projectName + 'invoice/queryInvoiceByReportId', qs.stringify({
                      valuationReportId:this.$route.query.reportId
                  }))
                  .then((res) => {
                    //   this.$Spin.hide();
                    console.log(res)
                      if (res.data.content) {
                          this.invoiceTitle = res.data.content.invoiceTitle;
                          this.taxpayerNum = res.data.content.taxpayerNum;
                          this.email = res.data.content.email;
                          this.phoneNo = res.data.content.phoneNo;
                          this.invoiceId = res.data.content.id;
                      }
                  });
          },
        
          saveInvoiceInfo: function() { //保存发票信息
              var emailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/; //邮箱
              var phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/; //电话
              if (!this.noInvoice) {
                  var params = {
                      valuationReportId: this.$route.query.reportId,
                      invoiceTitle: this.invoiceTitle,
                      taxpayerNum: this.taxpayerNum,
                      email: this.email,
                      phoneNo: this.phoneNo,
                      id: this.invoiceId,
                      invoiceFlag: !this.noInvoice ? '1' : '0' //1开发票   0不开发票
                  }

                  if (!this.invoiceTitle) {
                      this.$Message.info('请输入发票抬头', 5);
                      return false
                  }
                  if (!this.taxpayerNum) {
                      this.$Message.info('请输入纳税人识别号', 5);
                      return false
                  }
                  if (!this.email) {
                      this.$Message.info('请输入电子邮件', 5);
                      return false
                  } else {
                      if (!emailReg.test(this.email)) {
                          this.$Message.info('请输入电子邮件', 5);
                          return false
                      }
                  }
                  if (!this.phoneNo) {
                      this.$Message.info('请输入手机号', 5);
                      return false
                  } else {
                      if (!phoneReg.test(this.phoneNo)) {
                          this.$Message.info('请输入手机号', 5);
                          return false;
                      }
                  }

              } else {
                  var params = {
                      valuationReportId:this.$route.query.reportId,
                      id: this.invoiceId,
                      invoiceFlag: !this.noInvoice ? '1' : '0' //1开发票   0不开发票
                  }
              }
              this.$post(this.projectName + 'invoice/insertOrUpdateInvoice', qs.stringify(params))
                  .then((res) => {
                      console.log(res.data)
                      this.payment = false;
                      this.paymentCode = true;
                      this.creatQrCode()
                  });
          },
          creatQrCode: function() { //生成支付的二维码
              $(".qrcode").html('');
              var urltest = API_CONFIG.devServer.proxy['/api'].target;
              var text = urltest + this.projectName + '/pay/qrcallback?reportId=' + (this.$route.query.reportId);
              var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                  text: text,
                  width: 130,
                  height: 130,
                  colorDark: "#000000",
                  colorLight: "#ffffff",
                //   top:30,
                  correctLevel: QRCode.CorrectLevel.H,
              })
              this.payIsSuccess()
          },
          payIsSuccess: function() { //支付信息--轮询
                var this_ = this;
                var id = this.$route.query.reportId;
                this_.timer = setInterval(function() {
                  this_.$post(this_.projectName+ '/pay/autoFindOrder', qs.stringify({
                          id: id
                      }))
                      .then((res) => {
                          console.log(res.data)
                          if (res.data == '1') { //订单状态为1表示支付成功 ，0未支付
                              clearInterval(this_.timer); //关闭定时器
                              this_.paymentCode = false;
                              this_.paySuccess = true;
                          }
                      });
              }, 1000)
          },
          modelSaveclose: function() {
				this.noInvoice = false;
			},
			modelPaySuccess: function() { //监听付款成功的弹框
				if (!this.paySuccess) {
					this.$router.push({
						path: '/valuationList'
					})
				}
			},
			codeAlert: function() { //监听付款码关闭的时候
				if (!this.paymentCode) {
					clearInterval(this.timer); //关闭定时器
				}
			},


        //   成功后跳转  列表界面
        jumpLists:function(){  
            this.$router.push({
                path: '/valuationList'
            })
        },



      }

  }
</script>
  
  
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="less">
   
    .relevantQuestionMain{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .paybg{
        width: 100%;
        height:300px;
        background: url(../../assets/img/filledTuring/zhifu.png);
        background-size: 100% 100%;
        overflow: hidden;
    }
    .payLeft{
        width: 48%;
        height: 100%;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .payLeft img{
        height: 50%;
        float: left;
    }
    .payRight{
        width: 52%;
        height: 100%;
        float:left;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .paymia{
        width: 100%;
        height: 220px;
        overflow: hidden;
    }
    .valuationpPrice{
        width: 100%;
        overflow: hidden;
    }
    .valuationpPrice img{
        width: 23px;
        display: block;
        float: left;
        /* margin-top: 4px; */
        margin-right: 10px;
        margin-top: 10px;
    }
    .valuationpPrice span{
        float: left;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        display: block;
        margin-right: 30px;
        margin-top: 4.5px;
    }
    .valuationpPrice p{
        font-size: 25px;
        color: #00E8FF;
        float: left;
        font-weight: 600;
    }
    .valuationpPrice i{
        font-size: 15px;
    }
    .valuationEssentials{
        width:100%;
        overflow: hidden;
    }
    .valuationEssentials li{
        width: 50%;
        float: left;
        margin-top: 10px;
        color: #fff;
        font-size: 13px;
        line-height: 18px;
    }
    .valuationEssentials li i{
        width: 4px;
        height: 4px;
        background-color: #00E8FF;
        border-radius: 3px;
        display: block;
        margin-right: 5px;
        float: left;
        margin-top: 8px;
    }
    .payButton{
        width: 125px;
        height: 30px;
        line-height: 30px;
        background-color: #00E8FF;
        color: #0000A1;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        border-radius: 3px;
        margin-top: 20px;
        cursor: pointer;
    }
	/deep/ .spin-container{
        background:rgba(000, 000, 000, 0.3);
    }
    /deep/ .ivu-spin-fix{
        background:rgba(000, 000, 000, 0.3);
    }
   /deep/ .ivu-spin-dot{
    width:120px;
    height: 120px;
   }
   /deep/ .ivu-modal{
     top:24%;
   }
   /deep/ .ivu-modal-mask{
    background:rgba(0, 0, 0, 0.6);
   }
   /deep/ .ivu-modal-content{
        width: 700px;
        // height: 300px;
       
   }
   /deep/ .ivu-modal{
        width: 700px !important;
        height: 420px;
        // padding-bottom: 30px;
        background:url("../../assets/img/filledTuring/一键生成报告弹窗.png") no-repeat;
        background-size: 100% 100%;
   }
   /deep/ .ivu-active-bg .hint{
        margin-top: 120px;
   }
//    /deep/ .ivu-active-bg{
//         height: 380px;
//         background:url("../../assets/img/filledTuring/一键生成报告低.png") no-repeat;
//         background-size: 100% 100%;
//    }
   /deep/ .ivu-modal-content{
    background: transparent;
   }

   /deep/ input[type="radio"]:checked+label::before{
        width: 18px;
        height: 18px;
        background-color: #00FAFF;
        // border:#00FAFF;
   }
   /deep/ input[type="radio"]+label::before{
        width: 18px;
        height: 18px;
        margin-right: 10px;
        // border:1px solid #00FAFF;
   }
   /deep/ .ivu-modal-footer{
    display: none;
   }
   /deep/ .ivu-icon-ios-close:before{
  
   
    color: #fff;
    opacity: 0;

   }
   /deep/ .ivu-modal-close{
    width: 40px !important;
    height: 40px !important;
    background:url("../../assets/img/filledTuring/guanbi.png");
    background-size: 100% 100%;
   }
   /deep/ .ivu-modal-close .ivu-icon-ios-close{
    width: 40px !important;
    height: 40px !important;
   }
    .hint{
        width: 75%;
        margin: auto;
    }
    .hint-success{
        width: 75%;
        margin: auto;
    }
    .report_style{
        // width: 90%;
        color: #fff;
        font-size: 16px;
        margin-top: 28px;
        font-weight: 400;
        overflow: hidden;

    }
    .report_style p{
        float: left;
        display: block;
        margin-top: 12px;
    }
    .report_style span{
        font-size: 25px;
        color: #00FAFF;
        float: left;
        display: block;
        font-weight: 400;
        margin-top: 2px;
    }
    .report_style img{
        width: 25px;
        float: left;
        display: block;
        margin-right: 20px;
        margin-top: 10px;
    }
    .report_style i{
        font-size: 16px;
    }
    .is_invoice{
        color: #fff;
        font-size: 16px;
        margin-top: 10px;
        font-weight: 400;
        overflow: hidden;
    }
    .is_invoice img{
        width: 25px;
        float: left;
        margin-right:20px;
    }
    .is_invoice p{
        float: left;
        margin-right: 20px;
    }
    .yes{
        float: left;
        margin-right: 30px;
    }
    .no{
        float: left;
    }
    .check_info{
        background-color: rgba(0,195,231,0.7);
        // opacity: 0.7;
        border-radius: 10px;
        width: 100%;
        padding-left: 3%;
        padding-right: 3%;
        margin:10px auto 0 auto;
        padding-bottom: 20px;
    }
    .check_title{
        color: #fff;
        padding-top: 10px;
        overflow: hidden;
    }
    .check_title span{
        float: left;
        display: block;
    }
    .check_title span:nth-child(2){
        font-size: 16px;
    }
    .check_title span:nth-child(1){
        width: 6px;
        height: 20px;
        background: #77EFFF;
        margin-right: 25px;
    }
    .check_title span:nth-child(3){
        margin-top: 3px;
    }
    .needed{
        overflow: hidden;
        margin-top: 10px;
        // padding-bottom: 20px;
    }
    .needed span{
        width: 128px;
        text-align:right;
        color: #fff;
        float: left;
    }
    .needed input{
        width: 300px;
        height: 24px;
        float: left;
        margin-left: 10px;
        background: #1FC6FF;
        color: #fff;
        border-radius: 3px;
        padding-left: 10px;
        padding-right: 10px;
    }
    input::-webkit-input-placeholder {
         color: #fff;
    }
    .pay_now{
        width: 210px;
        height: 60px;
        line-height: 60px;
        // background:url("../../assets/img/filledTuring/baocun.png")
        background:url("../../assets/img/filledTuring/baocun.png") no-repeat;
        z-index: 999999;
        background-size: 100% 100%;
        color: #fff;
        text-align: center;
        margin: auto;
    }

    .erweima{
        width: 185px;
        position: absolute;
        left: 255px;
        top: 50px;
    }
    .qrcode{
        width: 130px;
        margin:70px auto 0 auto;
    }
    .qrcode img{
        // margin: auto;
        // width: 100%;
        // display: block;
        position: absolute;
        left: 30px;
        top:30px;
    }
    .selectionMethod{
        color: #fff;
        text-align: center;
        margin-top: 10px;
        font-size: 16px;
    }
    .payIcon{
        width: 120px;
        margin:10px auto;
        overflow: hidden;
    }
    .payIcon img:nth-child(1){
       float: left;
    }
    .payIcon img:nth-child(2){
       float: right;
    }
    .payPrice{
        font-size:20px;
        color: #00FAFF;
        text-align: center;
        margin-top: 25px;
        font-weight: 500;
    }
    .paySuccess{
        width: 100%;
        overflow: hidden;
    }
    .paySuccess img{
        width: 150px;
        display: block;
        margin:70px auto 0 auto;
    }
    .hint-success p:nth-child(1){
        font-size: 20px;
        color: #00FAFF;
        margin-top: 10px;
        font-weight: 400;
    }
    .hint-success p:nth-child(2){
        font-size: 14px;
        color: #fff;
        margin-top: 10px;
    }
    .hint-success p:nth-child(3){
        font-size: 14px;
        color: #fff;
        margin-top: 10px;
    }

    .hint-sure{
        width: 210px;
        height: 60px;
        line-height: 60px;
        // background:url("../../assets/img/filledTuring/baocun.png")
        background:url("../../assets/img/filledTuring/baocun.png") no-repeat;
        z-index: 999999;
        background-size: 100% 100%;
        color: #fff;
        text-align: center;
        margin: auto;
    }
  </style>
  