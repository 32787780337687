<template>
    <div id="forgetPassWord" style="margin: 0;">
    	<!-- 忘记密码 -->
    	<!-- 导航栏-->
    	<header class="back-white">
    		<div class="flow-header-container topone">
    			<div class="logo">
    				<router-link to="/" tag="a"></router-link>
    			</div>
    			<div class="header-two-right">
    				<div class="country-container">
    					<div class="china active"> <span class="country-name">中国</span> <span
    							class="country-img countryImgActive"></span> </div>
    				</div>
    				<router-link to="/" tag="a" class="router-link-active">首页</router-link>
    			</div>
    		</div>
    	</header>
    	<div class="mobile_header">
    		<img src="../../assets/img/logo-08.svg" @click="toIndex()" />
    		<button @click="toLogin()">返回登陆</button>
    	</div>
    	<div class="vertical">
    		<div class="forgetPage">
    			<p>找回密码</p>
    			<ul v-show="itemStatus == 1">
    				<li>
    					<input type="text" id="userAccount" @blur="phoneNumVerify()" v-model="loginName"
    						autocomplete="off" placeholder="请填写手机号/邮箱" />
    					<p class="warn">
    						<span class="noExistent" v-show="loginNameStatus == 2">账号不存在，请注册后使用</span>
    						<span class="error" v-show="loginNameStatus == 1">格式错误，请重新填写</span>
    					</p>
    				</li>
    				<li>
    					<div class="inputContent">
    						<input type="text" id="picturecode" placeholder="请输入图片验证码" @blur="pictureCdoeVerify()"
    							v-model="pictureCode" />
    						<div>
    							<img class="picture" alt="图形验证码-心流" :src="imgCodeSrc">
    							<span class="pictureCodeBtn" @click="getPictureCode()">换一张</span>
    						</div>
    						<p class="warn">
    							<span class="noExistent" v-show="pictureCodeStatus == 1">图片验证码不正确</span>
    							<span class="noExistent" v-show="pictureCodeStatus == 2">请输入图片验证码</span>
    						</p>
    					</div>
    				</li>
    				<li class="step-two">
    					<div class="inputContent">
    						<input type="text" id="code" placeholder="6位验证码" v-model="code" @blur="codeVerify()" />
    						<span class="getCode" data-onOff="true" @click="sendPhoneCode()">{{phoneCodeText}}</span>
    					</div>
    					<p class="warn">
    						<span class="noExistent" v-show="codeStatus == 1">验证码不正确</span>
    						<span class="noExistent" v-show="codeStatus == 3">请输入验证码</span>
    						<span class="error" v-show="codeStatus == 2">验证码失效</span>
    					</p>
    				</li>
    			</ul>
    			<ul v-show="itemStatus == 2">
    				<li>
    					<input type="password" id="newpass" placeholder="设置新的登录密码" autocomplete="off"
    						@blur="passwordVerify()" v-model="password" />
    					<p class="warn">
    						<span class="error" v-show="passwordStatus == 1">密码格式有误</span>
    					</p>
    				</li>
    				<li>
    					<input type="password" id="confirmNewPass" placeholder="确认新的登录密码" @blur="password2Verify()"
    						v-model="password2" />
    					<p class="warn">
    						<span class="error" style="top:73px;" v-show="passwordStatus2 == 1">两次输入的密码不一致，请重新输入</span>
    					</p>
    				</li>
    			</ul>
    			<button class="btn" id="stepTwoBut" v-show="itemStatus == 1" @click="secondNextBtn()">下一步</button>
    			<button class="btn" id="stepThreeBut" v-show="itemStatus == 2"
    				@click="passwordCommitBtn()">确认设置</button>
    			<!-- <div class="forget-step step-four" v-show="itemStatus == 4">
    				<div>
    					<img src="../../assets/img/success@2x.png" alt="重置密码成功-心流" />
    					<p>恭喜您，密码重置成功！</p>
    				</div>
    				<a @click="toLogin()">立即登录</a>
    			</div> -->
    		</div>
    	</div>
    	<div class="logogFoot">
    		<span>
    			<span>©2018心流网 <a target="_blank" href="https://beian.miit.gov.cn">
    					京ICP备18036155号-2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;增值电信业务经营许可证:京B2-20182113&nbsp;&nbsp;&nbsp;</a></span>
    			<span>
    				<a target="_blank"
    					href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502037130">京公网安备11010502037130号</a>
    			</span>
    		</span>
    	</div>
    </div>
</template>
<script>
    import qs from 'qs';
    export default {
    	name: "forgetPassword",
    	data() {
    		return {
    			itemStatus: 1, // tab索引
    			loginName: "", // 用户账号
    			loginNameStatus: 0, // 账号反馈状态
    			type: 0, // 类型
    			imgCodeSrc: "", // 图片验证码src
    			uuid: "", // 图片验证码id
    			pictureCode: "", // 图片验证码
    			pictureCodeStatus: 0, // 图片验证码反馈状态
    			phoneCodeText: "获取验证码", // btn文案
    			code: "", // 验证码
    			codeStatus: 0, // 验证码反馈状态
    			password: "", // 密码
    			passwordStatus: 0, // 密码反馈状态
    			password2: "", // 确认密码
    			passwordStatus2: 0, // 确认密码反馈状态
    		}
    	},
    	mounted() {
    		this.getPictureCode()
    	},
    	methods: {
    		toIndex: function() {
    			this.$router.push({
    				name: 'home',
    				path: '/',
    			})
    		},
    		checkAccount: function(str) {
    			var emailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/; //邮箱
    			var phoneReg = /^1[3|4|5|6|7|8|9]\d{9}$/; //电话
    			if (str.indexOf("@") != -1) {
    				// 邮箱
    				if (!emailReg.test(str)) {
    					return false;
    				}
    				this.type = 1;
    				return true;
    			} else {
    				// 手机
    				if (!phoneReg.test(str)) {
    					return false;
    				}
    				this.type = 2;
    				return true;
    			}
    		},
    		// 手机号码验证
    		phoneNumVerify: function() {
    			var this_ = this;
    			if (!this_.loginName || !this_.checkAccount(this_.loginName)) {
    				this_.loginNameStatus = 1;
    			} else {
    				this_.loginNameStatus = 0;
    				var obj = {
    					loginName: this_.loginName,
    					type: this_.type
    				}
    				this_.$post("flow/customer/checkCustomer", qs.stringify(obj)).then((res) => {
    					var data = res.data;
    					if (data.code == 103) {
    						this_.loginNameStatus = 2;
    					} else {
    						this_.loginNameStatus = 0;
    					}
    				})
    			}
    		},
    		// 获取图片验证码
    		getPictureCode: function() {
    			this.$get("flow/customer/getVerifiCode", "").then((res) => {
    				if (res.code == 200 && res.content) {
    					this.uuid = res.content.uuid;
    					this.imgCodeSrc = res.content.image;
    				}
    			})
    		},
    		// 验证图片验证码
    		pictureCdoeVerify: function() {
    			var this_ = this;
    			if (!this_.pictureCode) {
    				this_.pictureCodeStatus = 2;
    			} else {
    				this_.$get("flow/customer/checkVerifiCode", {
    					test: this_.pictureCode,
    					uuid: this_.uuid
    				}).then((res) => {
    					if (res.code != 200) {
    						this_.pictureCodeStatus = 1;
    					} else if (res.code == 200) {
    						this_.pictureCodeStatus = 0;
    					}
    				})
    			}
    		},
    		// 倒计时
    		countDown: function(time) {
    			var that = this;
    			var times;
    			if (time == 0) {
    				clearTimeout(times)
    				that.phoneCodeText = "获取验证码";
    			} else {
    				that.phoneCodeText = time + "s";
    				time--;
    				times = setTimeout(function() {
    					that.countDown(time)
    				}, 1000)
    			}
    		},
    		// 发送验证码
    		sendPhoneCode: function() {
    			var this_ = this;
    			if (!this_.loginName || !this_.checkAccount(this_.loginName)) {
    				this_.loginNameStatus = 1;
    			} else if (!this_.pictureCode) {
    				this_.pictureCodeStatus = 2;
    			} else if (this_.pictureCode && this_.pictureCodeStatus == 0 && this_.phoneCodeText == "获取验证码") {
    				this_.countDown(60);
    				var obj = {
    					verifiCode: this_.pictureCode,
    					uuid: this_.uuid
    				}
    				if (this_.type == 2) {
    					// 手机注册
    					var posturl = "flow/customer/sendPhoneCode";
    					obj.phoneNum = this_.loginName;
    				} else {
    					// 邮箱注册
    					var posturl = "flow/customer/sendEmailCode";
    					obj.mail = this_.loginName;
    				}
    				this_.$post(posturl, qs.stringify(obj)).then((res) => {
    					var data = res.data;
    					if (data.code != 200) {
    						this_.$Message.info("发送失败")
    						return false;
    					}
    				})
    			}
    		},
    		// 验证码验证
    		codeVerify: function() {
    			if (this.code) {
    				this.codeStatus = 0;
    			} else {
    				this.codeStatus = 3;
    			}
    		},
    		// 2.下一步
    		secondNextBtn: function() {
    			var this_ = this;
    			if (!this_.loginName || !this_.checkAccount(this_.loginName)) {
    				this_.loginNameStatus = 1;
    				return false;
    			} else if (!this_.pictureCode) {
    				this_.pictureCodeStatus = 2;
    				return false;
    			} else if (this_.pictureCodeStatus == 1) {
    				return false;
    			} else if (!this_.code) {
    				this_.codeStatus = 3;
    			} else if (this_.pictureCodeStatus == 0) {
    				var obj = {
    					phoneNoOrMail: this_.loginName,
    					code: this_.code
    				}
    				this_.$post("flow/customer/checkIdentifyingCode", qs.stringify(obj)).then((res) => {
    					var data = res.data;
    					if (data.code == 104) { // 验证码输入错误
    						this_.codeStatus = 1;
    					} else if (data.code == 106) { // 验证码失效
    						this_.codeStatus = 2;
    					} else if (data.code == 200) {
    						this_.itemStatus = 2;
    					}
    				})
    			}
    		},
    		// 密码
    		passwordVerify: function() {
    			var passwordReg = /^[A-Za-z0-9@#$%]{6,16}$/; //密码   特殊符号可以自己添加 @ # $ %
    			var this_ = this;
    			if (!this_.password || !passwordReg.test(this_.password)) {
    				this_.passwordStatus = 1;
    			} else {
    				this_.passwordStatus = 0;
    			}
    		},
    		// 确认密码
    		password2Verify: function() {
    			if (this.password2 != this.password) {
    				this.passwordStatus2 = 1;
    			} else {
    				this.passwordStatus2 = 0;
    			}
    		},
    		// 确认设置
    		passwordCommitBtn: function() {
    			var this_ = this;
    			if (!this_.password) {
    				this_.passwordStatus = 1;
    			} else if (!this_.password2 || this_.password2 != this_.password) {
    				this_.passwordStatus2 = 1;
    			} else {
    				var obj = {
    					phoneNoOrMail: this_.loginName,
    					type: this_.type,
    					password: this_.password,
    					password2: this_.password2
    				}
    				this_.$post("flow/customer/resetPassword", qs.stringify(obj)).then((res) => {
    					var data = res.data;
    					if (data.code == 200) {
    						this_.toLogin()
    					} else if (data.code == 100) {
    						this_.passwordStatus2 = 1;
    					}
    				})
    			}
    		},
    		toLogin: function() {
    			this.$router.push({
    				name: "login",
    				path: "/login"
    			})
    		}
    	}
    }
</script>
<style scoped>
::-webkit-input-placeholder {
		/* WebKit browsers，webkit内核浏览器 */
		color: #BFBFBF;
	}

	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #BFBFBF;
	}

	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #BFBFBF;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #BFBFBF;
	}

	html,
	body,
	#app {
		height: 100%;
	}

	header.back-white {
		height: 80px;
		border: none;
		position: absolute;
		background: rgba(20, 89, 244, 0.06);
	}

	.topone>div.logo {
		width: 207px;
		height: 59px;
		background: url(../../assets/img/login/logo_06.png) no-repeat;
		background-size: 200px;
	}

	#forgetPassWord {
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;
		background: url(../../assets/img/login/backgroundImage.jpg) no-repeat center center;
		background-size: cover;
	}

	.vertical {
		width: 1200px;
		height: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.forgetPage {
		width: 500px;
		height: 420px;
		background: #FFFFFF;
		position: relative;
	}

	.forgetPage>p {
		font-size: 22px;
		font-family: 'Microsoft YaHei';
		font-weight: 400;
		color: #41403F;
		text-align: center;
		margin: 46px 0 49px;
		font-weight: bold;
	}

	.forgetPage ul {
		width: 420px;
		height: 294px;
		margin: 0 auto;
	}

	.forgetPage ul li {
		position: relative;
	}

	.forgetPage ul li input[type='text'],
	.forgetPage ul li input[type='password'] {
		width: calc(100% - 23px);
		height: 40px;
		background: #FFFFFF;
		border: 1px solid #BFBFBF;
		padding-left: 23px;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		-ms-border-radius: 6px;
		-o-border-radius: 6px;
	}

	.forgetPage ul li .inputContent input[type='text'] {
		width: 218px;
	}

	.inputContent,
	#confirmNewPass {
		margin-top: 28px;
	}

	.inputContent>div {
		display: inline-block;
		position: relative;
		top: -3px;
	}

	.inputContent>div img {
		vertical-align: middle;
		margin-left: 20px;
		margin-right: 13px;
	}

	.pictureCodeBtn {
		font-size: 12px;
		font-family: 'Microsoft YaHei';
		font-weight: 400;
		color: #999999;
		position: absolute;
		bottom: 2px;
		width: 40px;
		cursor: pointer;
	}

	.btn {
		width: 420px;
		height: 40px;
		line-height: 40px;
		background: #1459F4;
		font-size: 16px;
		color: #fff;
		cursor: pointer;
		position: absolute;
		left: 50%;
		bottom: 46px;
		margin-left: -210px;
	}

	.getCode {
		width: 120px;
		height: 40px;
		line-height: 40px;
		background: #FFFFFF;
		border: 1px solid #1459F4;
		color: #1459F4;
		font-size: 14px;
		text-align: center;
		margin-left: 20px;
		cursor: pointer;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		-ms-border-radius: 6px;
		-o-border-radius: 6px;
	}

	.warn span {
		color: red;
		position: absolute;
		top: 45px;
		/* display: none; */
	}


	.logogFoot {
		width: 100%;
		height: 80px;
		background: #fff;
		font-size: 14px;
		color: #585858;
		text-align: center;
		line-height: 80px;
		position: absolute;
		bottom: 0;
	}

	.logogFoot a {
		color: #585858;
		cursor: pointer;
	}

	@media screen and (max-width: 765px) {
		#app {
			margin: 0;
		}

		header {
			display: none;
		}

		.mobile_header {
			display: block;
		}

		#forgetPassWord {
			background: #fff;
		}

		.forgetPage {
			width: 100%;
			height: 700px;
		}

		.vertical {
			width: 100%;
		}

		.forgetPage ul {
			width: 90%;
		}

		.forgetPage ul li input[type='text'],
		.forgetPage ul li input[type='password'] {
			width: 100%;
			height: 65px;
			border: 1px solid #ddd;
			font-size: 14PX;
			line-height: 65px;
			padding: 10px 10px;
		}

		.forgetPage ul li .inputContent input[type='text'] {
			width: 53%;
		}

		.pictureCodeBtn {
			width: 70px;
		}

		.getCode {
			width: 30%;
			height: 65px;
			line-height: 65px;
		}

		.btn {
			width: 90%;
			height: 65px;
			line-height: 65px;
			position: initial;
			display: block;
			margin: 0 auto;
		}

		.warn span {
			top: 65px;
		}

		.logogFoot {
			display: none;
		}
	}
</style>