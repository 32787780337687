<template>
	<div class="formMain">
		<div class="formBodyPositioning">
			<Header />
			<div class="formWidth">
				<div class="formMainModule">
					<div class="formInfomationTitle">估值报告</div>
					<PaymentInformation />
					<div class="relevantQuestionMain">
						<Navigation :nav="navList" />
						<div class="relevantQuestion major-balance">
							<span class="unit">单位：元</span>
							<table class="table" id="tableOne" border="0" style="border-collapse: collapse;">
								<thead>
									<tr class="noInput">
										<th>项目</th>
										<th v-for="(item, index) in yearList">{{item}}</th>
									</tr>
								</thead>
								<tbody>
									<tr class="trOne" data-class="monetaryCapital" style="visibility: hidden;">
										<td><span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList"></td>
									</tr>
									<tr class="trOne even noInput" data-class="totalCurrentAssets">
										<td>
											流动资产
											<span class="reRed">*</span>
										</td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number"
												v-model="liabilities.totalCurrentAssets[i]" />
											<span class="showInput">
												{{judgingCondition('totalCurrentAssets',i)?formatCurrency.formateNum(liabilities.totalCurrentAssets[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo" data-class="monetaryCapital">
										<td>货币资金<span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="liabilities.monetaryCapital[i]" />
											<span class="showInput">
												{{judgingCondition('monetaryCapital',i)?formatCurrency.formateNum(liabilities.monetaryCapital[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo tipTerm" data-class="receivables">
										<td>应收账款 <span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="liabilities.receivables[i]" />
											<span class="showInput">
												{{judgingCondition('receivables',i)?formatCurrency.formateNum(liabilities.receivables[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo tipTerm" data-class="inventory">
										<td>存货 <span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="liabilities.inventory[i]" />
											<span class="showInput">
												{{judgingCondition('inventory',i)?formatCurrency.formateNum(liabilities.inventory[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trOne even noInput" data-class="totalNonCurrentAssets">
										<td>
											非流动资产 <span class="reRed">*</span>
										</td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number"
												v-model="liabilities.totalNonCurrentAssets[i]" />
											<span class="showInput">
												{{judgingCondition('totalNonCurrentAssets',i)?formatCurrency.formateNum(liabilities.totalNonCurrentAssets[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo tipTerm" data-class="fixedAssets">
										<td>
											固定资产<span class="reRed">*</span>
										</td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="liabilities.fixedAssets[i]" />
											<span class="showInput">
												{{judgingCondition('fixedAssets',i)?formatCurrency.formateNum(liabilities.fixedAssets[i],2):'-'}}
											</span>
										</td>
									</tr>
									<!--资产合计-->
									<tr class="trOne total totalAssets grey" data-class="totalAssets">
										<td>资产总计</td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" v-model="liabilities.totalAssets[i]"
												disabled>
											<span class="showInput">
												{{judgingCondition('totalAssets',i)?formatCurrency.formateNum(liabilities.totalAssets[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trOne total totalCurrentAssets" data-class="totalNonLiabilities">
										<td>流动负债 <span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number"
												v-model="liabilities.totalNonLiabilities[i]" />
											<span class="showInput">
												{{judgingCondition('totalNonLiabilities',i)?formatCurrency.formateNum(liabilities.totalNonLiabilities[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo" data-class="shortLoan">
										<td>短期借款 <span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="liabilities.shortLoan[i]" />
											<span class="showInput">
												{{judgingCondition('shortLoan',i)?formatCurrency.formateNum(liabilities.shortLoan[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo" data-class="accountsPayable">
										<td>应付账款 <span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="liabilities.accountsPayable[i]" />
											<span class="showInput">
												{{judgingCondition('accountsPayable',i)?formatCurrency.formateNum(liabilities.accountsPayable[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trOne" data-class="totalFixedLiabilities">
										<td>非流动负债 <span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number"
												v-model="liabilities.totalFixedLiabilities[i]" />
											<span class="showInput">
												{{judgingCondition('totalFixedLiabilities',i)?formatCurrency.formateNum(liabilities.totalFixedLiabilities[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo" data-class="longLoan">
										<td>长期借款 <span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="liabilities.longLoan[i]" />
											<span class="showInput">
												{{judgingCondition('longLoan',i)?formatCurrency.formateNum(liabilities.longLoan[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trOne" data-class="totalLiabilities">
										<td>负债总计</td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number"
												v-model="liabilities.totalLiabilities[i]" disabled />
											<span class="showInput">
												{{judgingCondition('totalLiabilities',i)?formatCurrency.formateNum(liabilities.totalLiabilities[i],2):'-'}}
											</span>

										</td>
									</tr>
									<tr class="trOne" data-class="totalParentEquity">
										<td>
											归属于母公司所有者权益<span class="reRed">*</span>
										</td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="liabilities.totalParentEquity[i]" />
											<span class="showInput">
												{{judgingCondition('totalParentEquity',i)?formatCurrency.formateNum(liabilities.totalParentEquity[i],2):'-'}}
											</span>
										</td>
									</tr>

									<tr class="trOne" data-class="minorityEquity">
										<td>
											少数股东权益<span class="reRed">*</span>
										</td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid"
												v-model="liabilities.minorityEquity[i]" />
											<span class="showInput">
												{{judgingCondition('minorityEquity',i)?formatCurrency.formateNum(liabilities.minorityEquity[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trOne" data-class="totalOwnershipInterest">
										<td>所有者权益（或股东权益)</td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number"
												v-model="liabilities.totalOwnershipInterest[i]" disabled />
											<span class="showInput">
												{{judgingCondition('totalOwnershipInterest',i)?formatCurrency.formateNum(liabilities.totalOwnershipInterest[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trOne" data-class="totalLiabilitiesEquity">
										<td>负债和所有者权益(或股东权益)</td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number"
												v-model="liabilities.totalLiabilitiesEquity[i]" disabled />
											<span class="showInput">
												{{judgingCondition('totalLiabilitiesEquity',i)?formatCurrency.formateNum(liabilities.totalLiabilitiesEquity[i],2):'-'}}
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>


<script>
	// 头部组件引入
	import Header from '../../common/header/header.vue';
	// 尾部组件引入
	import Footer from '../../common/footer/footer.vue';
	import '../../assets/css/common.css';
	import PaymentInformation from '../../common/getInfo/paymentInformation.vue';
	import Navigation from '../../common/getInfo/navigation.vue';
	import qs from 'qs'

	export default {
		data() {
			return {
				navList: [ '基本信息','软性指标',  '资产负债表','利润表', '审核状态'],
				liabilities: {
					monetaryCapital: [],
					receivables: [],
					inventory: [],
					fixedAssets: [],
					shortLoan: [],
					accountsPayable: [],
					longLoan: [],
					otherEquity: [],
					totalParentEquity: [],
					minorityEquity: [],
					totalCurrentAssets: [], //流动资产合计
					totalNonCurrentAssets: [], //非流动资产合计
					totalAssets: [], //资产总计
					totalNonLiabilities: [], //流动负债合计
					totalFixedLiabilities: [], //非流动负债合计
					totalLiabilities: [], //负债总计
					totalOwnershipInterest: [], //所有者权益（或股东权益)合计
					totalLiabilitiesEquity: [], //负债和所有者权益(或股东权益)总计
				},
				yearList: [],
				projectName: ''
			}
		},
		components: {
			Header,
			Footer,
			PaymentInformation,
			Navigation
		},
		mounted() {
			if (this.$route.query.type == 12) {
				this.projectName = this.domain.turingProject;
			}
			this.init()
		},
		methods: {
			judgingCondition: function(key, i, index) {
				if (this.liabilities[key][i] === 0) {
					return true
				} else if (this.liabilities[key][i] != 0 && this.liabilities[key][i] != '-') {
					return true
				} else {
					return false
				}
			},
			init: function() {
				this.$post(this.projectName + 'auditInfoShow/qryFinanceInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						console.log(res.data)
						var this_ = this;
						var liabilitiesKey = Object.keys(this.liabilities); //获取资产负债表对象的 key
						this.yearList = res.data.content.financeInfo.year;
						if (res.data.content.financeInfo.liabilities) {
							var returnliabilitiesKey = Object.keys(JSON.parse(res.data.content.financeInfo
								.liabilities)); //后台数据返回的资产负债表对象的 key
							liabilitiesKey.forEach((elem, index) => {
								returnliabilitiesKey.forEach((item, i) => {
									this_.yearList.forEach((yearItem, y) => {
										if (elem == item) {
											this_.liabilities[elem][y] = JSON.parse(
												res.data.content.financeInfo
												.liabilities)[
												elem][y];
										} else if (!this_.liabilities[elem]) {
											if (this_.yearList.length == 2) {
												this_.liabilities[elem] = ['', '']
											} else if (this_.yearList.length == 3) {
												this_.liabilities[elem] = ['', '', '']
											}
										}
									})
								})
							})
							console.log(this.liabilities)
						}

					});
			}
		}
	}
</script>

<style scoped src="../../assets/css/myGuZhi.css"></style>
<style scoped>
	span.unit {
		margin-top: 0;
	}

	.relevantQuestionMain {
		height: 79%;
		margin-top: 3%;
	}
	
	@media screen and (max-height:800px){
		.relevantQuestionMain{
			margin-top: 5%;
		}
	}
</style>
