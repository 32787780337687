import * as Html2Canvas from 'html2canvas'
import JsPdf from 'jspdf'
export default {
	// init(arr,i,pdf){
	//     this.getPdf(arr,i,pdf)
	// },

	getPdf(arr, i, title, pdf) {
		var that = this;

		var options = {
			scale: 4,
			useCORS: true
		}

		Html2Canvas(document.getElementById(arr[i]), options).then(function(canvas) {

			console.log(window.devicePixelRatio)
			if (!pdf) {
				pdf = JsPdf('', 'pt', 'a4');
			}
			var contentWidth = canvas.width;
			var contentHeight = canvas.height;

			var pageHeight = contentWidth / 1160 * 1648.4;
			var leftHeight = contentHeight;


			var imgWidth = 595.28;
			var imgHeight = 595.28 / contentWidth * contentHeight;
			var pageData = canvas.toDataURL('image/jpeg', 1.0);
			if (i != 0) {
				pdf.addPage();
			}
			pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);

			i++;
			if (i < arr.length) {
				that.getPdf(arr, i, title, pdf)
			}
			if (i == arr.length) {
				document.getElementById("loading").style.display = "none";
				pdf.save(title);
			}
		})
	}

}
