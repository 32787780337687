<template>
	<div class="formMain">
	 <div class="formBodyPositioning">
	   <Header />
	   <div class="formWidth">
		 <div class="formMainModule">
		   <div class="formInfomationTitle">
			 资产负债表
		   </div>
 
		   <div class="relevantQuestionMain" id="xqBox" >
			 <div class="relevantQuestion major-balance">
				
				 <!-- 填写说明 -->
				 <div class="fillinginstructions">
					 <p style="margin-top:9px">
						 <img src="../../assets/img/filledTuring/利润表.png">填写说明
					 </p>
					 <p>
						 <!-- 1、最近一年带<span class="reRed">*</span>号科目为估值必填项，其他两年为选填。 -->
					 </p>
					 <p>
						 1、因本表只选取了估值必填的明细科目，如果存在其他明细科目，本表没有对应科目的情况，请将合计数填在对应一级科目处。
					 </p>
					 <p>
						2、必填项中，如果该科目在对应的会计年度不存在余额，请填0。
					</p>
				 </div>
				 <div class="float-box-fixed" v-if="theadfixed">
						<tr class="noInput">
							<th>项目</th>
							<th v-for="(item, index) in yearList">{{item}}</th>
						</tr>
				 </div>
			   <!--单位简介-->
					   <span class="unit">单位：元</span>
			   <table class="table" id="tableOne" border="0" style="border-collapse: collapse;">
				<!-- :class="[scrollTop>130?'float-box-fixed':'']" -->
					<thead v-if="thead">
						<tr class="noInput">
							<th>项目</th>
							<!-- <th>2022</th>
							<th>2021</th>
							<th>2020</th> -->
							<th v-for="(item, index) in yearList">{{item}}</th>
							
						</tr>
					</thead>

					
					 <tbody>
						 <tr class="trOne" data-class="monetaryCapital" style="visibility: hidden;">
						   <td><span class="reRed">*</span></td>
						   <td v-for="(item, i) in yearList">
							 
						   </td>
					   </tr>   
					   <tr class="trOne even noInput" data-class="totalCurrentAssets">
							 <td>
								 流动资产
								 <span class="reRed">*</span>
							 </td>
							 <td v-for="(item, i) in yearList">
								 <input type="number" data-type="number" v-model="liabilities.totalCurrentAssets[i]"
								  @blur="((ev)=>{blur(ev,liabilities,'totalCurrentAssets',i)})" @focus="focus($event)" 
								 />
								 <span class="showInput"  @click="inputFocus($event)">
								 {{judgingCondition('totalCurrentAssets',i)?formatCurrency.formateNum(liabilities.totalCurrentAssets[i],2):'-'}}
								 </span>
							 </td>
						   </tr>
					   <tr class="trTwo" data-class="monetaryCapital">
						   <td>货币资金<span class="reRed">*</span></td>
						   <td v-for="(item, i) in yearList">
							 <input type="number" data-type="number" ref="forbid"
							   v-model="liabilities.monetaryCapital[i]"
							   @blur="((ev)=>{blur(ev,liabilities,'monetaryCapital',i)})"
							   @focus="focus($event)" />
							 <span class="showInput" @click="inputFocus($event)">
							   {{judgingCondition('monetaryCapital',i)?formatCurrency.formateNum(liabilities.monetaryCapital[i],2):'-'}}
							 </span>
						   </td>
					   </tr>   
					   <tr class="trTwo tipTerm" data-class="receivables">
						   <td>应收账款 <span class="reRed">*</span></td>
						   <td v-for="(item, i) in yearList">
							 <input type="number" data-type="number" ref="forbid"
							   v-model="liabilities.receivables[i]"
							   @blur="((ev)=>{blur(ev,liabilities,'receivables',i)})" @focus="focus($event)" />
							 <span class="showInput" @click="inputFocus($event)">
							   {{judgingCondition('receivables',i)?formatCurrency.formateNum(liabilities.receivables[i],2):'-'}}
							 </span>
						   </td>
						 </tr>
						 <tr class="trTwo tipTerm" data-class="inventory">
						   <td>存货 <span class="reRed">*</span></td>
						   <td v-for="(item, i) in yearList">
							 <input type="number" data-type="number" ref="forbid"
							   v-model="liabilities.inventory[i]"
							   @blur="((ev)=>{blur(ev,liabilities,'inventory',i)})" @focus="focus($event)" />
							 <span class="showInput" @click="inputFocus($event)">
							   {{judgingCondition('inventory',i)?formatCurrency.formateNum(liabilities.inventory[i],2):'-'}}
							 </span>
						   </td>
						 </tr>
 
 
						 
						 <tr class="trOne even noInput" data-class="totalNonCurrentAssets">
							   <td>
								 非流动资产  <span class="reRed">*</span>
							 </td>
							 <td v-for="(item, i) in yearList">
									 <input type="number" data-type="number"
										 v-model="liabilities.totalNonCurrentAssets[i]"  
										 @blur="((ev)=>{blur(ev,liabilities,'totalNonCurrentAssets',i)})" @focus="focus($event)"
										 />
									 <span class="showInput" @click="inputFocus($event)">
										 {{judgingCondition('totalNonCurrentAssets',i)?formatCurrency.formateNum(liabilities.totalNonCurrentAssets[i],2):'-'}}
									 </span>
							 </td>
						 </tr>
						 <tr class="trTwo tipTerm" data-class="fixedAssets">
							   <td>
								 固定资产<span class="reRed">*</span>
							   </td> 
							   <td v-for="(item, i) in yearList">
								 <input type="number" data-type="number" ref="forbid"
								   v-model="liabilities.fixedAssets[i]"
								   @blur="((ev)=>{blur(ev,liabilities,'fixedAssets',i)})" @focus="focus($event)" />
								 <span class="showInput" @click="inputFocus($event)">
								   {{judgingCondition('fixedAssets',i)?formatCurrency.formateNum(liabilities.fixedAssets[i],2):'-'}}
								 </span>
							   </td>
						 </tr>
						 
						   <!--资产合计-->
						 <tr class="trOne total totalAssets grey" data-class="totalAssets">
						   <td>资产总计</td>
						   <td v-for="(item, i) in yearList">
							 <input type="number" data-type="number" v-model="liabilities.totalAssets[i]" disabled>
							 <span class="showInput">
							   {{judgingCondition('totalAssets',i)?formatCurrency.formateNum(liabilities.totalAssets[i],2):'-'}}
							 </span>
						   
							 <!-- <input type="number" data-type="number" ref="forbid" v-model="liabilities.totalAssets[i]"
							  @blur="((ev)=>{blur(ev,liabilities,'totalAssets',i)})" @focus="focus($event)"/>
							 <span class="showInput" @click="inputFocus($event)">
							   {{judgingCondition('totalAssets',i)?formatCurrency.formateNum(liabilities.totalAssets[i],2):'-'}}
							 </span> -->
						   </td>
						 </tr>
						 <tr class="trOne total totalCurrentAssets" data-class="totalNonLiabilities">
								 <td>流动负债  <span class="reRed">*</span></td>
								 <td v-for="(item, i) in yearList">
									 <input type="number" data-type="number" v-model="liabilities.totalNonLiabilities[i]"
										 @blur="((ev)=>{blur(ev,liabilities,'totalNonLiabilities',i)})"
										 @focus="focus($event)"  
									 />
									 <span class="showInput" @click="inputFocus($event)">
										 {{judgingCondition('totalNonLiabilities',i)?formatCurrency.formateNum(liabilities.totalNonLiabilities[i],2):'-'}}
									 </span>
								 </td>
							 </tr>
						 <tr class="trTwo" data-class="shortLoan">
						   <td>短期借款 <span class="reRed">*</span></td>
						   <td v-for="(item, i) in yearList">
							 <input type="number" data-type="number" ref="forbid"
							   v-model="liabilities.shortLoan[i]"
							   @blur="((ev)=>{blur(ev,liabilities,'shortLoan',i)})" @focus="focus($event)" />
							 <span class="showInput" @click="inputFocus($event)">
							   {{judgingCondition('shortLoan',i)?formatCurrency.formateNum(liabilities.shortLoan[i],2):'-'}}
							 </span>
						   </td>
						 </tr>
						 <tr class="trTwo" data-class="accountsPayable">
							 <td>应付账款 <span class="reRed">*</span></td>
							 <td v-for="(item, i) in yearList">
							   <input type="number" data-type="number" ref="forbid"
								 v-model="liabilities.accountsPayable[i]"
								 @blur="((ev)=>{blur(ev,liabilities,'accountsPayable',i)})"
								 @focus="focus($event)" />
							   <span class="showInput" @click="inputFocus($event)">
								 {{judgingCondition('accountsPayable',i)?formatCurrency.formateNum(liabilities.accountsPayable[i],2):'-'}}
							   </span>
							 </td>
						   </tr>
						   <tr class="trOne" data-class="totalFixedLiabilities">
								 <td>非流动负债  <span class="reRed">*</span></td>
								 <td v-for="(item, i) in yearList">
									 <input type="number" data-type="number"
										 v-model="liabilities.totalFixedLiabilities[i]" 
										 @blur="((ev)=>{blur(ev,liabilities,'totalFixedLiabilities',i)})" @focus="focus($event)" 
									 />
									 <span class="showInput" @click="inputFocus($event)">
										 {{judgingCondition('totalFixedLiabilities',i)?formatCurrency.formateNum(liabilities.totalFixedLiabilities[i],2):'-'}}
									 </span>
								 </td>
							 </tr>
						   <!-- <tr class="trOne noInput">
							 <td>非流动负债:</td>
							 <td v-for="(item, i) in yearList"></td>
						   </tr> -->
						   <tr class="trTwo" data-class="longLoan">
							 <td>长期借款 <span class="reRed">*</span></td>
							 <td v-for="(item, i) in yearList">
							   <input type="number" data-type="number" ref="forbid"
								 v-model="liabilities.longLoan[i]"
								 @blur="((ev)=>{blur(ev,liabilities,'longLoan',i)})" @focus="focus($event)" />
							   <span class="showInput" @click="inputFocus($event)">
								 {{judgingCondition('longLoan',i)?formatCurrency.formateNum(liabilities.longLoan[i],2):'-'}}
							   </span>
							 </td>
						   </tr>
						   
						   <tr class="trOne" data-class="totalLiabilities">
							 <td>负债总计</td>
							 <td v-for="(item, i) in yearList">
							   <!-- <input type="number" data-type="number" v-model="liabilities.totalLiabilities[i]" 
							   @blur="((ev)=>{blur(ev,liabilities,'totalLiabilities',i)})" @focus="focus($event)"
							   />
							   <span class="showInput" @click="inputFocus($event)">
								 {{judgingCondition('totalLiabilities',i)?formatCurrency.formateNum(liabilities.totalLiabilities[i],2):'-'}}
							   </span> -->
							   <input type="number" data-type="number" v-model="liabilities.totalLiabilities[i]"
										 disabled />
							 <span class="showInput">
								 {{judgingCondition('totalLiabilities',i)?formatCurrency.formateNum(liabilities.totalLiabilities[i],2):'-'}}
							 </span>
		   
							 </td>
										 </tr>
 
 
						   <tr class="trOne" data-class="totalParentEquity">
							   <td>
								 归属于母公司所有者权益
								 <span class="reRed">*</span>
							   </td>
							   <!-- <td v-for="(item, i) in yearList">
								 <input type="number" data-type="number" v-model="liabilities.totalParentEquity[i]"/>
								 <span class="showInput">
								   {{judgingCondition('totalParentEquity',i)?formatCurrency.formateNum(liabilities.totalParentEquity[i],2):'-'}}
								 </span>
							   </td> -->
							   <td v-for="(item, i) in yearList">
								 <input type="number" data-type="number" ref="forbid"
								   v-model="liabilities.totalParentEquity[i]"
								   @blur="((ev)=>{blur(ev,liabilities,'totalParentEquity',i)})"
								   @focus="focus($event)" />
								 <span class="showInput" @click="inputFocus($event)">
								   {{judgingCondition('totalParentEquity',i)?formatCurrency.formateNum(liabilities.totalParentEquity[i],2):'-'}}
								 </span>
							   </td>
						   </tr>
 
						   <tr class="trOne" data-class="minorityEquity">
							   <td>
								 少数股东权益
								 <span class="reRed">*</span>
							   </td>
							   <td v-for="(item, i) in yearList">
								 <input type="number" data-type="number" ref="forbid"
								   v-model="liabilities.minorityEquity[i]"
								   @blur="((ev)=>{blur(ev,liabilities,'minorityEquity',i)})"
								   @focus="focus($event)" />
								 <span class="showInput" @click="inputFocus($event)">
								   {{judgingCondition('minorityEquity',i)?formatCurrency.formateNum(liabilities.minorityEquity[i],2):'-'}}
								 </span>
							   </td>
						   </tr>
						   <tr class="trOne" data-class="totalOwnershipInterest">
							 <td>所有者权益（或股东权益)</td>
							 <td v-for="(item, i) in yearList">
								 <input type="number" data-type="number"
										 v-model="liabilities.totalOwnershipInterest[i]" disabled />
								 <span class="showInput">
										 {{judgingCondition('totalOwnershipInterest',i)?formatCurrency.formateNum(liabilities.totalOwnershipInterest[i],2):'-'}}
								 </span>
							   <!-- <input type="number" data-type="number"
								 v-model="liabilities.totalOwnershipInterest[i]" 
								 @blur="((ev)=>{blur(ev,liabilities,'totalOwnershipInterest',i)})"
								 @focus="focus($event)"
								 />
							   <span class="showInput" @click="inputFocus($event)">
								 {{judgingCondition('totalOwnershipInterest',i)?formatCurrency.formateNum(liabilities.totalOwnershipInterest[i],2):'-'}}
							   </span> -->
							 </td>
						   </tr>
						   <tr class="trOne" data-class="totalLiabilitiesEquity">
							 <td>负债和所有者权益(或股东权益)</td>
							 <td v-for="(item, i) in yearList">
							   <!-- <input type="number" data-type="number"
								 v-model="liabilities.totalLiabilitiesEquity[i]"  
								 @blur="((ev)=>{blur(ev,liabilities,'totalLiabilitiesEquity',i)})"
								 @focus="focus($event)"
							   />
							   <span class="showInput" @click="inputFocus($event)">
								 {{judgingCondition('totalLiabilitiesEquity',i)?formatCurrency.formateNum(liabilities.totalLiabilitiesEquity[i],2):'-'}}
							   </span> -->
								 <input type="number" data-type="number"
										 v-model="liabilities.totalLiabilitiesEquity[i]" disabled />
								 <span class="showInput">
										 {{judgingCondition('totalLiabilitiesEquity',i)?formatCurrency.formateNum(liabilities.totalLiabilitiesEquity[i],2):'-'}}
								 </span>
							 </td>
						   </tr>
					 </tbody>
			   </table>
			   <span class="balanceSheetTips">
				 <!-- 提示：资产总计=负债和所有者权益 -->
			   </span>
			   <div class="saveBox">
				 <span class="closePage" id="saveButton"  @click="closeStep">关闭</span>
				 <button class="save" id="saveButton" data-isClick="false" @click="saveButton" v-preventReClick>保 存</button>
			   </div>
 
			 </div>
		   </div>
		 </div>
	   </div>
	   <Footer />
	  
	   <dialogBar 
	   v-model="myAlert" 
	   :type="popButonType" 
	   :title='markedWords' content="" 
	   @confirmJump="clickConfirmJump()"  
	   @cacheTrueMask="clickCacheTrueMaskJump()"  
	   @cacheMask="clickCacheMaskJump()"></dialogBar>
 
	 </div>
   </div>
 </template>
 <script>
   // 头部组件引入
   import Header from '../../common/header/header.vue';
   // 尾部组件引入
   import Footer from '../../common/footer/footer.vue';
	 import qs from 'qs'
	 import axios from 'axios';
	 import {
		 mapState,
		 mapMutations
	 } from 'vuex';
 
   import dialogBar from './popue.vue'
   import $ from 'jquery'
 
	 export default {
		 data() {
			 return {
				 yearList: [],
				 status: 2, // 判断当前页面是否可以修改  1代表不能修改   (1 审核通过  2 未填写完成  3 填写完成未支付)
				 id: '',
				 url: '', // 保存数据的接口路径
				 financialTips: false,
				 myAlert: false, //上传模板的弹框
				 success: false, //上传模板成功的弹框
				 error: false, //上传模板错误的弹框
				 reportId: '',
				 liabilities: {
					 monetaryCapital: [],
					 receivables: [],
					 inventory: [],
					 fixedAssets: [],
					 shortLoan: [],
					 accountsPayable: [],					
					 longLoan: [],
					 otherEquity: [],
					 totalParentEquity: [],
					 minorityEquity: [],
					 totalCurrentAssets: [], //流动资产合计
					 totalNonCurrentAssets: [], //非流动资产合计
					 totalAssets: [], //资产总计
					 totalNonLiabilities: [], //流动负债合计
					 totalFixedLiabilities: [], //非流动负债合计
					 totalLiabilities: [], //负债总计
					 totalOwnershipInterest: [], //所有者权益（或股东权益)合计
					 totalLiabilitiesEquity: [], //负债和所有者权益(或股东权益)总计
		  
				 },
				 projectName: '',
				 popButonType:"closeData",
				 markedWords:"",
				 versions:"2",
				 scrollTop:0,
				 theadfixed:false,
				 thead:true
			 }
		 },
		 components: {
			 Header,
			 Footer,
			 dialogBar
		 },
		 mounted() {
			   if (this.$route.query.type == 12) {
				 this.projectName = this.domain.turingProject;
			 } 
			 var this_ = this;
			 this.pageModify('false');
			 this.getBalanceSheet()
			 this.$nextTick(function() {
			 })
		 },
		 computed: {
				// 在计算属性获取到这个xqBox的dom元素
				xqBox() {
						return document.querySelector("#xqBox");
				}
		 },
		 updated() {
			 if (this.status == 1) {
				 if (this.$refs.forbid) {
					 this.$refs.forbid.forEach((item, i) => {
						 item.disabled = "true";
						 item.style.backgroundColor = '#fff';
						 item.nextSibling.style.backgroundColor = '#fff';
						 item.nextSibling.style.pointerEvents = 'none';
					 })
				 }
			 } else {
				 if (this.$refs.forbid) {
					 this.$refs.forbid.forEach((item, i) => {
						 item.disabled = "";
					 })
				 }
			 }
		 },
		 methods: {
			 ...mapMutations(['pageModify']),
			 judgingCondition: function(key, i, index) {
				 if (this.liabilities[key][i] === 0) {
					 return true
				 } else if (this.liabilities[key][i] != 0 && this.liabilities[key][i] != '-') {
					 return true
				 } else {
					 return false
				 }
			 },
			 blur: function(e, data, name, num) { //data：资产负债表的字段   name：具体的科目名称   num：科目的具体下标	
				 e.currentTarget.nextSibling.style.display = 'block';
				 e.currentTarget.style.borderColor = '1px solid #00E4FF';
				 var y = String(data[name][num]).indexOf("."); //获取小数点的位置
				 var count = String(data[name][num]).length - y; //获取小数点后的个数
				 this.pageModify('true');
				 if (data[name][num]) {
					 if (String(data[name][num]).indexOf(".") != -1) {
						 this.$set(this.liabilities[name], num, Math.round(Number(data[name][num]) * 100) / 100) //保留两位
					 } else {
						 if (data[name][num].length > 15) { //判断数据的合理性 
							 this.$Message.error({
								 background: true,
								 content: '请输入合理数据'
							 });
							 this.$set(this.liabilities[name], num, '')
						 } else {
							 this.$set(this.liabilities[name], num, Number(data[name][num]))
							 // console.log(this.liabilities[name], num, Number(data[name][num]))
						 }
					 }
				 } else {
					if(data[name][num]=='0'){
						this.$set(this.liabilities[name], num , Number(data[name][num]))
					}else{
						this.$set(this.liabilities[name], num ,'')
					}
					
				 }
 
				 for (var i = 0; i < this.yearList.length; i++) {
 
					 // 资产总计  可编辑
					 this.liabilities.totalAssets[i] = Number(this.liabilities.totalCurrentAssets[i]) + Number(this
					 .liabilities.totalNonCurrentAssets[i]);
 
 
					 // 负债总计  可编辑
					   this.liabilities.totalLiabilities[i] = Number(this.liabilities.totalNonLiabilities[i]) + Number(
					 this.liabilities.totalFixedLiabilities[i]);
		
 
					 // 所有者权益（或股东权益)合计
					 this.liabilities.totalOwnershipInterest[i] = Number(this.liabilities.totalParentEquity[i]) +
					 Number(this.liabilities.minorityEquity[i]);
					 
 
					 // 负债和所有者权益(或股东权益)总计	
					 this.liabilities.totalLiabilitiesEquity[i] = Number(this.liabilities.totalLiabilities[i]) + Number(
						 this.liabilities.totalOwnershipInterest[i]);
		
				 }

				 //调用缓存共用方法
				if(!this.$route.query.reportId || (this.$route.query.reportId && !this.contentLiabilities)||(this.$route.query.reportId && this.contentLiabilities && this.isCacheValue == '0')){
					this.method.caching("liabilitiesInfo",JSON.stringify(this.liabilities),this.$route.query.type,this.$route.query.stage,this)
				}
				 
			 },
			 
			 focus: function(e) {
			 },
			 inputFocus: function(e) {
				 e.currentTarget.style.display = 'none';
				 e.currentTarget.previousSibling.style.display = 'block';
				 e.currentTarget.previousSibling.focus();
			 },
			 
			 getBalanceSheet: function(close) {
				 var this_ = this;
				 // if (this.$route.query.reportId) {
					 var url = '';
					 url = this.projectName + 'financialLiabilitiesDetail/qryLiabilitesInfo';
					 this.$post(url, qs.stringify({
							 reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
							 reportType: this.$route.query.type,
							 versions: this.versions,
							 stage: this.$route.query.stage
						 }))
						 .then((res) => {
							 var liabilitiesKey = Object.keys(this.liabilities); //获取资产负债表对象的 key
							 if (this.$route.query.type != 7) {
								 // console.log("111")
								 for (var i = 0; i < res.data.content.year.length; i++) {
									 res.data.content.year[i] = res.data.content.year[i].replace("年", "年12月31日");
								 }
							 }
							 this.yearList = res.data.content.year;
							 this.id = res.data.content.id;
							 this.contentLiabilities = res.data.content.liabilities
							 // 调用 关闭 清空数据------------
							 this.isCacheValue = res.data.content.isCache
							 if(this.isCacheValue == '0' && close=='0'){
								this.method.clearCaching(this.$route.query.type,this.$route.query.stage,this)
							 }
							// 调用 关闭 清空数据------------
							 if (res.data.content.liabilities) {
								
								// 缓存数据
								console.log(sessionStorage.isHistoryCache)
								if(res.data.content.isCache == "0" && !sessionStorage.isHistoryCache){
									this.myAlert = true;
									this.popButonType = 'cacheType';
									this.markedWords = '您上次填写过估值报告，是否载入缓存信息？';

									// 清除 0.00
									var dataKey = Object.keys(this.liabilities); //获取对象的 key
									dataKey.forEach((key, idx) => {
										this.yearList.forEach((item, i) => {
											this.liabilities[key].push("")
										})
									})
									
								}else{ //正式数据
									var returnliabilitiesKey = Object.keys(JSON.parse(res.data.content
										.liabilities)); //后台数据返回的资产负债表对象的 key
									liabilitiesKey.forEach((elem, index) => {
										returnliabilitiesKey.forEach((item, i) => {
											this_.yearList.forEach((yearItem, y) => {
												if (elem == item) {
													this_.liabilities[elem][y] = JSON.parse(
														res.data.content.liabilities)[
														elem][y];
												} else if (!this_.liabilities[elem]) {
													if (this_.yearList.length == 2) {
														this_.liabilities[elem] = ['', '']
													} else if (this_.yearList.length == 3) {
														this_.liabilities[elem] = ['', '', '']
													}
												}
											})
										})
									})
								}
							}
							else {
								 var dataKey = Object.keys(this.liabilities); //获取对象的 key
								 dataKey.forEach((key, idx) => {
									 this.yearList.forEach((item, i) => {
										 this.liabilities[key].push("")
									 })
								 })
							}
							this.xqBox.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
 
						 });

			 },
			 handleScroll: function() {
								 var scrollTop = this.xqBox.scrollTop;
								 this.scrollTop = scrollTop;
								 if(scrollTop>140){
									this.theadfixed = true
									this.thead = false
								 }else{
									this.theadfixed = false
									this.thead = true
								 }
								//  console.log(scrollTop)
			 },
			 saveButton: function() {
				 // console.log(typeof(this.liabilities.totalCurrentAssets[0]))
				 if (this.liabilities.totalCurrentAssets[0].length == 0) {
					 this.myAlert = true;
					 this.popButonType = 'notEmpty';
					 this.markedWords = '请填写流动资产，没有请填0。';
					   return false;
				 }
				 if (this.liabilities.monetaryCapital[0].length == 0) {
					 this.myAlert = true;
					 this.popButonType = 'notEmpty';
					 this.markedWords = '请填写货币资金，没有请填0。';
					   return false;
				 }
				 if (this.liabilities.receivables[0].length == 0) {
					 this.myAlert = true;
					 this.popButonType = 'notEmpty';
					 this.markedWords = '请填写应收账款，没有请填0。';
					   return false;
				 }
				 if (this.liabilities.inventory[0].length == 0) {
					 this.myAlert = true;
					 this.popButonType = 'notEmpty';
					 this.markedWords = '请填写存货，没有请填0。';
					   return false;
				 }
 
				 if (this.liabilities.totalNonCurrentAssets[0].length == 0) {
					 this.myAlert = true;
					 this.popButonType = 'notEmpty';
					 this.markedWords = '请填写非流动资产，没有请填0。';
					   return false;
				 }
 
				 if (this.liabilities.fixedAssets[0].length == 0) {
					 this.myAlert = true;
					 this.popButonType = 'notEmpty';
					 this.markedWords = '请填写固定资产，没有请填0。';
					   return false;
				 }
				 
				 if (this.liabilities.totalNonLiabilities[0].length == 0) {
							 this.myAlert = true;
							 this.popButonType = 'notEmpty';
							 this.markedWords = '请填写流动负债，没有请填0。';
							 return false;
				 }
				 if (this.liabilities.shortLoan[0].length == 0) {
					 this.myAlert = true;
					 this.popButonType = 'notEmpty';
					 this.markedWords = '请填写短期借款，没有请填0。';
					   return false;
				 }
 
				 if (this.liabilities.accountsPayable[0].length == 0) {
							 this.myAlert = true;
							 this.popButonType = 'notEmpty';
							 this.markedWords = '请填写应付账款，没有请填0。';
							 return false;
				 }
				 if (this.liabilities.totalFixedLiabilities[0].length == 0) {
							 this.myAlert = true;
							 this.popButonType = 'notEmpty';
							 this.markedWords = '请填写非流动负债，没有请填0。';
							 return false;
				 }
				 if (this.liabilities.longLoan[0].length == 0) {
					 this.myAlert = true;
					 this.popButonType = 'notEmpty';
					 this.markedWords = '请填写长期借款，没有请填0。';
					   return false;
				 }
				 
 
				 if (this.liabilities.totalParentEquity[0].length == 0) {
					 this.myAlert = true;
					 this.popButonType = 'notEmpty';
					 this.markedWords = '请填写归属于母公司所有者权益，没有请填0。';
					   return false;
				 }
 
				 if (this.liabilities.minorityEquity[0].length == 0) {
					 this.myAlert = true;
					 this.popButonType = 'notEmpty';
					 this.markedWords = '请填写少数股东权益，没有请填0。';
					   return false;
				 }
				 // console.log(this.liabilities)
				 // return;
				 var this_ = this;
				 var params = {
					 reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
					 stage: this.$route.query.stage,
					 id: this.id,
					 liabilitiesInfo: JSON.stringify(this.liabilities),
					   reportType:this.$route.query.type,
					 versions:this.versions
				 };
 
				 this.url = this.projectName + 'financialLiabilitiesDetail/save';
				 
 
				 this.$post(this.url, qs.stringify(params))
					 .then((res) => {
						 if (res.data.code == 200) {
							 this.financialTips = false;
								 setTimeout(function() {
									 this_.$router.push({
										 path: '/home',
										 query: {
											 stage: this_.$route.query.stage,
											 type: this_.$route.query.type,
											 reportId: res.data.content.valuationReportId
										 }
									 })
								 }, 500)
						 }
					 });
			 },
	   		closeStep: function() {
				 this.myAlert = true;
				 this.popButonType = 'closeData';
				 this.markedWords = '当前数据尚未保存，是否确定离开？';
			 },
	   		// 关闭弹出层  点击确定回调
			clickConfirmJump(){
				this.getBalanceSheet('0');
				this.$router.push({
					 path: '/home',
					 query: {
						 stage: this.$route.query.stage,
						 type: this.$route.query.type,
						 reportId: this.$route.query.reportId,
					 }
				})
			},
			
			//是  载入缓存
			clickCacheTrueMaskJump(){
				sessionStorage.isHistoryCache = 1;
				this.getBalanceSheet();
			},

			//否  载入缓存
			clickCacheMaskJump(){
				sessionStorage.isHistoryCache = 1;
				this.method.clearCaching(this.$route.query.type,this.$route.query.stage,this,'getBalanceSheet')
				// this.getBalanceSheet();
			}

		},
			
 
	 }
 </script>
 
 
 
 <!-- Add "scoped" attribute to limit CSS to this component only -->
 <style scoped src="../../assets/css/myGuZhi.css">
	
 </style>
 