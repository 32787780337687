import sensitiveWord from '../js/sensitiveWord.json'
import qs from 'qs'

export default {
	
	filterSensitiveWords(e, dataKey, whetherFinancial) { //过滤敏感词    e:dom对象  dataKey：基本信息key   whetherFinancial:是否是财务报表
		var that = this;
		var sensitiveWordsList = sensitiveWord.sensitiveWord;
		var sensitiveWordsFlag = false;
		for (var i = 0; i < sensitiveWordsList.length; i++) {
			if (e.value.indexOf(sensitiveWordsList[i]) != -1) {
				sensitiveWordsFlag = true;
			}
			var len = sensitiveWordsList[i].length;
			var str = '';
			for (var l = 0; l < len; l++) {
				str += '*'
			}
			var r = new RegExp(sensitiveWordsList[i], "ig");
			e.value = e.value.replace(r, str);
		}
		if (whetherFinancial == 'statement') {
			dataKey[0] = e.value;
		} else {
			dataKey[e.getAttribute('id')] = e.value;
		}
		return sensitiveWordsFlag
	},
	// 报告中估值结果单位转换及千分位展示(最大值、最小值)
	valuationResultConversion(min, max) {
		// 最小值
		if (Math.abs(min) / 10000 > 1) {
			if (Math.sign(min) == -1) { //值为负数
				min = '-' + this.formateNumTwo((Math.abs(min) / 10000).toFixed(2)) + '亿元'
			} else {
				min = this.formateNumTwo((Math.abs(min) / 10000).toFixed(2)) + '亿元'
			}
		} else {
			if (Math.sign(min) == -1) { ////值为负数
				min = '-' + this.formateNumTwo(Math.abs(min).toFixed(2)) + '万元';
			} else {
				min = this.formateNumTwo(Math.abs(min).toFixed(2)) + '万元';
			}
		}

		// 最大值
		if (Math.abs(max) / 10000 > 1) {
			if (Math.sign(max) == -1) { //值为负数
				max = '-' + this.formateNumTwo((Math.abs(max) / 10000).toFixed(2)) + '亿元'
			} else {
				max = this.formateNumTwo((Math.abs(max) / 10000).toFixed(2)) + '亿元'
			}
		} else {
			if (Math.sign(max) == -1) { ////值为负数
				max = '-' + this.formateNumTwo(Math.abs(max).toFixed(2)) + '万元';
			} else {
				max = this.formateNumTwo(Math.abs(max).toFixed(2)) + '万元';
			}
		}
		return min + '~' + max
	},
	resultConversion(data) { //单个数据换算单位和千分位展示
		if (Math.abs(data) / 10000 > 1) {
			if (Math.sign(data) == -1) { //值为负数
				data = '-' + this.formateNumTwo((Math.abs(data) / 10000).toFixed(2)) + '亿元'
			} else {
				data = this.formateNumTwo((Math.abs(data) / 10000).toFixed(2)) + '亿元'
			}
		} else {
			if (Math.sign(data) == -1) { //值为负数
				data = '-' + this.formateNumTwo(Math.abs(data).toFixed(2)) + '万元';
			} else {
				data = this.formateNumTwo(Math.abs(data).toFixed(2)) + '万元';
			}
		}
		return data
	},
	switchNum: function(min, max) {
		var unit = '';
		// 最小值
		if (Math.abs(min) / 10000 > 1) {
			if (Math.sign(min) == -1) { //值为负数
				min = (Math.abs(min) / 10000).toFixed(2)
			} else {
				min = (Math.abs(min) / 10000).toFixed(2)
			}
		} else {
			if (Math.sign(min) == -1) { ////值为负数
				min = Math.abs(min).toFixed(2)
			} else {
				min = Math.abs(min).toFixed(2)
			}
		}

		// 最大值
		if (Math.abs(max) / 10000 > 1) {
			if (Math.sign(max) == -1) { //值为负数
				max = (Math.abs(max) / 10000).toFixed(2)
			} else {
				max = (Math.abs(max) / 10000).toFixed(2)
			}
			unit = '亿元'
		} else {
			if (Math.sign(max) == -1) { ////值为负数
				max = Math.abs(max).toFixed(2)
			} else {
				max = Math.abs(max).toFixed(2)
			}
			unit = '万元'
		}
		return {
			min: min,
			max: max,
			unit: unit
		}
	},
	technologyNum:function(data){
		var unit='';
		if (Math.abs(data) / 10000 > 1) {
			if (Math.sign(data) == -1) { //值为负数
				data = (Math.abs(data) / 10000).toFixed(2)
			} else {
				data = (Math.abs(data) / 10000).toFixed(2)
			}
			unit = '亿元'
		} else {
			if (Math.sign(data) == -1) { ////值为负数
				data = Math.abs(data).toFixed(2)
			} else {
				data = Math.abs(data).toFixed(2)
			}
			unit = '万元'
		}
		return {
			data:data,
			unit: unit
		}
	},
	/**
	 * @func 数字格式化      千分位
	 * @param {string} str   需要格式化的数字
	 * @param {number} num   小数的位数
	 * @returns {string} str
	 */
	formateNumTwo(str, num) {
		var isnegative = true; // 表示正负数；false：小于0
		var value;
		if (isNaN(parseFloat(str))) {
			return;
		}
		if (parseFloat(str) < 0) {
			isnegative = false;
			value = str.split("-")[1]
		} else {
			value = str;
		}
		value = value.replace(/^\s+|\s+$/g, '');
		var right = value.split(".")[1] ? value.split(".")[1] : "0",
			rightValue;
		var left = value.split(".")[0].split("").reverse();
		if (right.length == num) {
			rightValue = right;
		} else if (right.length < num) {
			var i = 0,
				rightValue = right;
			while (num - right.length - i > 0) {
				rightValue += "0";
				i++;
			}

		} else {
			rightValue = right.substr(0, num)
		}
		var total = new Array();
		for (var i = 0; i < left.length; i++) {
			total.push(left[i]);
			if ((i + 1) % 3 == 0 && (i + 1) != left.length) {
				total.push(",");
			}
		}
		if (isnegative) {
			return num == 0 ? total.reverse().join("") : total.reverse().join("") + "." + rightValue;
		} else {
			return num == 0 ? "-" + total.reverse().join("") : "-" + total.reverse().join("") + "." + rightValue;
		}
	},
	// 填写页面缓存 (没有报告id没有数据和有报告id没有数据这两种情况进行缓存)
	caching(category,info,reportType,stage,_this){
		// 缓存接口调用===========
		var cacheIntervalTime = 2000; //缓存时间间隔秒数  （目前是5秒）
		var cacheCurrentTime = Date.now(); //当前时间
		if (sessionStorage.cacheTime) {
			if (Number(cacheCurrentTime) > (Number(sessionStorage.cacheTime) + cacheIntervalTime)) {
				sessionStorage.cacheTime = Date.now();
				// 缓存接口调用===========
				var url = '';
				// url = _this.domain.ajaxUrl + 'cache/saveCache';
				url = _this.domain.ajaxUrl+'cache/saveCache'
				var params = {
					category: category,
					info: info,
					reportType:reportType,
					stage: stage
				};
				_this.$post(url, qs.stringify(params))
					.then((res) => {
						// console.log(res)
				});
				// console.log('当前时间超过上次保存的时间5秒')
			} else {
				// console.log('当前时间没超过上次保存的时间5秒')
			}
		} else {
			sessionStorage.cacheTime = Date.now();
		}
	},
	// 清缓存调用
	clearCaching(type,stage,_this,getinfo){
		var url = '';
		url = _this.domain.ajaxUrl+'cache/deleteCache'
		var params = {
			reportType: type,
			stage: stage,
		};
		_this.$post(url, qs.stringify(params))
		.then((res) => {
			// 获取getinfo接口
			if(getinfo == 'getBaseInfo'){
				_this.getBaseInfo()
			}else if(getinfo == 'getBalanceSheet'){
				_this.getBalanceSheet()
			}else if(getinfo == 'getProfit'){
				_this.getProfit()
			}else if(getinfo == 'getQuestion'){
				_this.getQuestion()
			}
			
		});
	}


}
