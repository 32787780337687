<template>
	<div class="draw3dPie">
		<div v-if="chartData[0].y==100" class="chartsImg">
			<img src="../../assets/img/report/charts.svg" alt="第一股东-心流">
			<img src="../../assets/img/report/line.svg" alt="心流">
			<span>第一股东：100%</span>
		</div>
		<div v-else :id="id"></div>
	</div>
</template>
<script>
	import * as Highcharts from 'highcharts'
	import * as Exporting from 'highcharts/modules/exporting'
	import Highcharts3D from 'highcharts/highcharts-3d'
	Exporting(Highcharts)
	Highcharts3D(Highcharts)

	export default ({
		props: [
			'chartData', 'id'
		],
		data() {
			return {

			}
		},
		mounted() {
			var dataLen = this.chartData.length - 1;
			for (var i = dataLen; i >= 0; i--) {
				if (this.chartData[i].y == 0) {
					this.chartData.splice(i, 1);
				}
			}
			// console.log(this.chartData)

			if (this.chartData.length != 1) {
				this.init()
			}

		},
		methods: {
			init: function() {
				var chartData = this.chartData;
				// if (this.chartData.length == 5) {
				// 	var color1 = ['#D5D0FC', '#ACF5F9', '#FAFAFA', '#CAFFED', '#BADEF9'];
				// 	var color2 = ['#B7AEFD', '#43DBE4', '#D6D9DE', '#69E0B5', '#61AEFF'];
				// 	var y = 20;
				// } else if (this.chartData.length == 4) {
				// 	var color1 = ['#D5D0FC', '#ACF5F9', '#FAFAFA', '#BADEF9'];
				// 	var color2 = ['#B7AEFD', '#43DBE4', '#D6D9DE', '#61AEFF'];
				// 	var y = 20;
				// } else if (this.chartData.length == 3) {
				// 	var color1 = ['#D5D0FC', '#ACF5F9', '#BADEF9'];
				// 	var color2 = ['#B7AEFD', '#43DBE4', '#61AEFF'];
				// 	var y = 20;
				// } else if (this.chartData.length == 2) {
				// 	var color1 = ['#BADEF9', '#D5D0FC'];
				// 	var color2 = ['#61AEFF', '#B7AEFD'];
				// 	var y = 100;
				// } else if (this.chartData.length == 1) {
				// 	var color1 = ['#BADEF9'];
				// 	var color2 = ['#61AEFF'];
				// 	var y = 20;
				// }

				var color1 = ['#BADEF9', '#D5D0FC', '#ACF5F9', '#FAFAFA', '#CAFFED'];
				var color2 = ['#61AEFF', '#B7AEFD', '#43DBE4', '#D6D9DE', '#69E0B5'];

				Highcharts.chart(this.id, {
					chart: {
						type: 'pie',
						backgroundColor: 'rgba(255, 255, 255, 0)', // 设置背景颜色透明 默认是白色
						options3d: {
							enabled: true,
							alpha: 68,
							beta: 0
						}
					},
					credits: {
						enabled: false
					},
					exporting: {
						enabled: false
					},
					title: {
						text: ''
					},
					subtitle: {
						// text: ''
					},
					labels: {
						style: {
							fontSize: 18
						}
					},
					plotOptions: {
						pie: {
							innerSize: 200,
							depth: 100,
							size: 320,
							// allowPointSelect: true,
							cursor: 'pointer',
							colors: ['#61AEFF', '#B7AEFD', '#43DBE4', '#D6D9DE', '#69E0B5'],
							dataLabels: {
								enabled: true,
								style: {
									fontSize: "18px",
									textOutline: 'none' //去掉文字带白边
								},
								format: '{point.name}:{point.y}%'
							},
							// 			connectorShape: function(labelPosition, connectorPosition, options) {
							// 				var touchingSliceAt = connectorPosition.touchingSliceAt,
							// 					alignment = labelPosition.alignment,
							// 					left = 0,
							// 					right = 0;
							// 				if (alignment == 'left') {
							// 					left = labelPosition.x
							// 					right = labelPosition.x
							// 				} else {
							// 					left = labelPosition.x
							// 					right = labelPosition.x
							// 				}
							// 				return ['M',
							// 					left,
							// 					labelPosition.y,
							// 					'L',
							// 					right,
							// 					labelPosition.y,
							// 					'L',
							// 					touchingSliceAt.x,
							// 					touchingSliceAt.y
							// 				];
							// 			}
							// slicedOffset: 20 //设置偏离中心点的距离
						}
					},
					series: [{
						data: this.chartData,
						// colors: [{ // 注意！！！如果是柱状图请使用color，如果是面积图请使用fillColor
						// 	linearGradient: {
						// 		x1: 1,
						// 		y1: 0,
						// 		x2: 0,
						// 		y2: 0.5
						// 	},
						// 	stops: [
						// 		[0, color1[0]],
						// 		[1, color2[0]]
						// 	]
						// }, { // 注意！！！如果是柱状图请使用color，如果是面积图请使用fillColor
						// 	linearGradient: {
						// 		x1: 1,
						// 		y1: 0,
						// 		x2: 0,
						// 		y2: 0.5
						// 	},
						// 	stops: [
						// 		[0, color1[1]],
						// 		[1, color2[1]]
						// 	]
						// }, { // 注意！！！如果是柱状图请使用color，如果是面积图请使用fillColor
						// 	linearGradient: {
						// 		x1: 0,
						// 		y1: 1,
						// 		x2: 1,
						// 		y2: 0
						// 	},
						// 	stops: [
						// 		[0, color1[2]],
						// 		[1, color2[2]]
						// 	]
						// }, { // 注意！！！如果是柱状图请使用color，如果是面积图请使用fillColor
						// 	linearGradient: {
						// 		x1: 0,
						// 		y1: 1,
						// 		x2: 1,
						// 		y2: 0
						// 	},
						// 	stops: [
						// 		[0, color1[3]],
						// 		[1, color2[3]]
						// 	]
						// }, { // 注意！！！如果是柱状图请使用color，如果是面积图请使用fillColor
						// 	linearGradient: {
						// 		x1: 0,
						// 		y1: 1,
						// 		x2: 1,
						// 		y2: 0
						// 	},
						// 	stops: [
						// 		[0, color1[4]],
						// 		[1, color2[4]]
						// 	]
						// }]
					}]
				})

			}
		}
	})
</script>
<style scoped lang="less">
	/* ----设置饼图连接线的样式---- */

	/deep/ .highcharts-pie-series .highcharts-data-label-connector {
		stroke-dasharray: 2, 2;
		stroke-width: 2px;
	}

	/*----设置饼图连接线的样式---- */
	.draw3dPie {
		width: 100%;
		height: 550px;
		position: relative;
	}

	#stockStructurePie {
		width: 860px;
		height: 550px;
		position: absolute;
		top: -30px;
		margin-left: 83px;
	}

	.chartsImg img {
		position: absolute;
	}

	.chartsImg img:nth-of-type(1) {
		width: 343px;
		top: 143px;
		left: 332px;
	}

	.chartsImg img:nth-of-type(2) {
		top: 58%;
		left: 62%;
	}

	.chartsImg span {
		font-size: 18px;
		color: #000;
		font-weight: bold;
		position: absolute;
		top: 66%;
		right: 6%;
	}
</style>
